import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'app/Services/admin.service';
import { BigpcService } from 'app/Services/bigpc.service';
import { DataService } from 'app/Services/data.service';
import { LanguageService } from 'app/Services/language.service';
import { LocalstorgeService } from 'app/Services/localstorge.service';
import { SmallPcService } from 'app/Services/small-pc.service';
import { AddressSelectionComponent } from 'app/address-selection/address-selection.component';
import { CommonService } from 'app/common.service';
import { InfopopupComponent } from 'app/infopopup/infopopup.component';
import { PortalAuthService } from 'app/login/auth.service';
import { AddressTypes } from 'app/models/addressTypes.interface';
import { HolidayInfo } from 'app/models/holidayCalender.interface';
import { SessionDataNew } from 'app/models/newSession.interface';
import { DatePickerComponent } from 'app/popups/date-picker/date-picker.component';
import { GeburtstagInfoComponent } from 'app/popups/geburtstag-info/geburtstag-info.component';
import { GeburtstagSelectonPopupComponent } from 'app/popups/geburtstag-selecton-popup/geburtstag-selecton-popup.component';
import { PricedetailPopupComponent } from 'app/popups/pricedetail-popup/pricedetail-popup.component';
import { RatePaymentSelectionPopupComponent } from 'app/popups/rate-payment-selection-popup/rate-payment-selection-popup.component';
import { VoucherNoticeComponent } from 'app/popups/voucher-notice/voucher-notice.component';
import { environment } from 'environments/environment';
import * as FileSaver from "file-saver";
import { EmailValidator } from "../bigpc/emailValidator";
import { UserAddress } from '../models/portalModels/portalUserAddress.interface';
import io from 'socket.io-client';
import { SafePipe } from '../pipes/safe.pipe';
import { PaymentErrorComponent } from 'app/popups/payment-error/payment-error.component';
import { RatePaymentRejectedInfoComponent } from 'app/popups/rate-payment-rejected-info/rate-payment-rejected-info.component';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { OrderService } from 'app/Services/order.service';

@Component({
  selector: "app-bigpc-new",
  templateUrl: "./bigpc-new.component.html",
  styleUrls: ["./bigpc-new.component.css"],
  providers: [SafePipe]
})
export class BigpcNewComponent implements OnInit {
  currentErrorIndex = 0;
  showFullScreenLoading = false;
  mandatoryCheckIndex: any;
  // showResendmTan: boolean = true;
  // showMtanError: boolean = false;
  numberRegex = /^[\d ]*$/;
  dialogOpened = false;
  showBigPC: Boolean = false;
  selectedQuality: any[] = [];
  zahlungspans = [];
  products = [];
  @Input("colors") colors: any = {};
  theme = 'Theme1'
  productInfo = {};
  options = [];
  page: Number = 1;
  loader: Boolean;
  disableAnchor: boolean = false;
  show_hide_Gutschein: boolean = false;
  calculatedQualities = [];
  ZahlungsartOptions: any;
  alarmIndex: number;
  voucher = {
    valid: false,
    voucherCode: '',
    error: false,
    voucherId: '',
  };

  fromDe: any = '';
  toDe: any = '';
  viewsnack: boolean = false;
  viewsnack4: boolean = false;
  deliveryDates: any;
  // ausgewaehlterRatenplan: any;

  // groupOrderMaxKM: Number;
  // enableOrderBtn: Boolean;
  url = '';
  sessionId = '';
  sessionData: SessionDataNew;
  languageData;
  clickedoption: string = "Bestelloptionen";
  PCOptions: any[] = [];
  telephonePattern= /^(?=.*\d)[\d\-\(\)\+\.\#\s]+$/;
  liefirfistQualityData = {};
  @Input("productInfoForWebPC") productInfoForWebPC: any = {};
  @Input("thridStagePdfs") thridStagePdfs: any;
  @Input("logOutUser") logOutUser: any;


  priceCalculationData: any;
  // co2data: any;
  // co2CompensateValue: any;
  gutscheinInfo: any;
  voucherCheckKey: boolean = false;
  weekOffsData: any[] = [];
  holiDaysData: HolidayInfo[];
  wunsterminOptionInfo: any = [];

  fromDateSet: any;
  toDateSet: any;
  pcErrorHandling = {
    prodcutHandleError: false,
    postalCodeHandleError: false,
  };

  localSessionUpdatedData: any;
  stadt: string;
  plz: string;
  previousSelectedId: string;
  // ratePaymentApproved: Boolean = true;
  showPdfError: boolean = false;
  primaryAddresses: UserAddress[] = [];
  allUserAddress: UserAddress[] = [];
  secondaryAddresses: UserAddress[] = [];
  billingAddresses: UserAddress[] = [];
  allAddresses: UserAddress[] = [];
  pricChangeAccept: boolean = false;

  viewsnack6: boolean = false;
  addNewAddress: boolean = false;

  editAddress: boolean = false;
  lieferIndex: any;
  addressType: string;
  tempOrderId;
  // Bank Account Info work Start here
  public bankDetailsForm: FormGroup;
  forIbanReadOnly: boolean = false;
  forAccountReadOnly: boolean = false;


  mandatoryCheck: boolean = false;
  mig: any;
  mengeno: boolean;
  disble: boolean;

  hostname: string;
  viewsnack2: boolean = false;
  languageVar: any;
  currentPage: string;
  selectedPageName: string;

  // affliateDetails work here
  affliateDetails: {
    amount?: any;
    zip?: any;
    productId?: any;
    unloadingPoints?: any;
    product?: any;
    productName?: any,
    affiliateId?: any;
    callBackUrl?: any;
    consumerId?: any;
  };

  notSelectedOption: boolean = false;

  // Second stage new variables
  dob: boolean = false;
  dobText: any;
  stationCount: number;
  selectedQuantity: any;
  stationFormGroup: FormGroup;
  billingAddressEnable: boolean = false;
  selectedBiilingOption: any;
  postalCodeDetails: any;
  RemainingMenge: Number;
  mengecheck: boolean;
  totalMenge = 0;

  deliveryAddress: any = [];
  billingAddress: any = [];
  selectedIndex: any;
  dobRechnungsadresse: boolean = false;
  // mtanInput = new FormControl("", { validators: Validators.required });
  contstrains = false;
  viewsnack10: boolean = false;
  agbRequired: any;
  tempHideSecondStage: boolean;
  @ViewChild("gutscheinEl") gutscheinEl: ElementRef;

  private scrollHelper: ScrollHelper = new ScrollHelper();
  // clickedPage: string = "page1";
  clickedPage: string;

  agbAccepted = {
    first: false,
    second: false,
    third: false,
  };

  isLoggedIn: boolean;
  @Input("registration_at_order") registration_at_order: any;
  @Input("isGuestUser") isGuestUser: any;
  @Output() navigateToPage = new EventEmitter();


  page_1: boolean = true;
  page_2: boolean = false;
  page_3: boolean = false;
  page_4: boolean = false;

  dataLayer = window["dataLayer"] || [];

  third_stage_pricechange: boolean = false;
  third_stage_button: boolean = false;
  label = '';
  symbol = '';
  activeUser: boolean = false;
  billingErr: boolean = false;

  // inLimitHouseHold = false;
  showSmallPc: boolean = false;
  blockedUser: boolean = false;

  checkOptionSet: any = [];
  optionSetEmpty: boolean = false;
  error = [];
  socket: any = null;
  socketId: string = '';


  secondStageFormValidation: boolean = false;
  bankFormValidation: boolean = false;
  ratePaymentValidation: any = false;
  orderStatus = '';
  oldPricesCart = [];
  priceChangewithPaymentMode = false;

  constructor(
    public bigPCService: BigpcService,
    public smallPCService: SmallPcService,
    public languageService: LanguageService,
    public dataService: DataService,
    public localStorageService: LocalstorgeService,

    public dialog: MatDialog,
    public adminService: AdminService,
    private portalAuthService: PortalAuthService,
    private _fb: FormBuilder,
    private commonService: CommonService,
    private SafePipe: SafePipe,
    private sso: ScrollStrategyOptions,
    public orderService: OrderService
  ) {
    let langData = this.languageService.getLocalLanguageData();
    this.languageData = langData["web-pc"] || {};
    this.languageService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data["web-pc"] || {};
    });

    this.url = window.location.href;
    let params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    this.hostname = decodeURIComponent(params.get("Domainname"));
    this.orderStatus = decodeURIComponent(params.get("tempOrderStatus"));
    this.url.includes('tempOrderStatus') ? this.orderStatus = decodeURIComponent(params.get("tempOrderStatus")) : this.orderStatus = '';

    this.currentPage = decodeURIComponent(params.get("stage"));
    this.selectedPageName = decodeURIComponent(params.get("page"));

    if (this.currentPage) {
      this.pageIdentify();
    } else {
      this.clickedPage = "page1"
    };


    this.bigPCService.showLoginPage$.subscribe(async (data) => {
      let getAffiliateStatus = await this.orderService.checkForAffliateQueryParams();
      if (getAffiliateStatus.status) {
        await this.validateSessionId();
        this.ngOnInit();
      };
    });
    // this.ratePayment = this.ratePayment.bind(this);

  };

  async ngOnInit() {
    console.log("-----------------------------------------------big pc triggred-----------------------------------------------")
    this.currentErrorIndex = 0;
    await this.getCurrentPageInfo();
    if (this.logOutUser) {
      this.addNewAddress = false;
      this.editAddress = false;
    };

    this.languageVar = this.productInfoForWebPC?.language;
    this.isLoggedIn = await this.portalAuthService.isUserLoggedIn() && !this.portalAuthService.isGuestUser();

    this.stationFormGroup = this._fb.group({
      stations: this._fb.array([]),
    });

    if (this.productInfoForWebPC) {
      if (Object.keys(this.productInfoForWebPC).length) {
        this.colors = this.productInfoForWebPC.colors;
        this.theme = 'Theme' + this.colors['theme'];
        this.pcErrorHandling.prodcutHandleError = false;
        this.label = this.productInfoForWebPC.unitsName;
        this.symbol = this.productInfoForWebPC.unitsSymbolForPc;
      } else {
        this.pcErrorHandling.prodcutHandleError = true;
      };
    };

    let affiliateDetailsExists = await this.orderService.checkForAffliateQueryParams();
    if (affiliateDetailsExists.status) {
      this.affliateDetails = this.orderService.affliateDetails;
      let resp = await this.orderService.affiliateSessionCreation(this.hostname).catch(() => false);
      if (resp && resp.sessionId) {
        this.sessionId = resp.sessionId;
        await this.smallPCService.getNewSessionData(this.sessionId).then(async (data: any) => {
          if (!data) {
            this.showBigPC = false;
            this.addNewAddress = false;
            return;
          } else {
            this.sessionData = data;
            if (!this.productInfoForWebPC) {
              let getProdcutInfo = { dynamicProductId: this.sessionData.product._id, tag: this.sessionData['tag'], all: true };
              await this.bigPCService.dynamicProductInfoForWebPc(getProdcutInfo).subscribe((_data: any) => {
                if (_data['success']) {
                  this.productInfoForWebPC = _data.data;
                  this.thridStagePdfs = this.productInfoForWebPC['pdfs'] || [];
                };
              });
            };

            await this.dynamicOptionsDataFetch(this.sessionData);
            await this.smallPCService.validateSessionData(this.sessionId);

            // if no session data stored in localstorage showing small pc
            if (this.sessionData && !Object.keys(this.sessionData).length) {
              this.showBigPC = false;
              return;
            };

            this.showBigPC = true;
            this.selectedQuantity = this.sessionData.quantity ? this.sessionData.quantity : 0;
            this.RemainingMenge = Number(this.sessionData.quantity);
            this.stationCount = this.sessionData.stations ? parseInt(this.sessionData.stations) : 1;
            this.postalCodeDetails = this.sessionData.postalCode;

            let ifpaymentOptionIsRaten: any = this.sessionData.ratePaymentSettings;

          };
        });
      };
    } else {
      //getting session data stored in localstorage
      await this.gettingNewSessionData(this.sessionId).then(async (data: any) => {
        if (!data) {
          if (this.portalAuthService.isUserLoggedIn() && !this.portalAuthService.isGuestUser()) {
            this.ifLogdinUsersCase();
          } else {
            this.showBigPC = false;
            this.addNewAddress = false;
            return;
          }
        } else {
          this.sessionData = data;
          this.localSessionUpdatedData = this.sessionData;
          await this.smallPCService.validateSessionData(this.sessionId);
          // if no session data stored in localstorage showing small pc
          if (this.sessionData && !Object.keys(this.sessionData).length) {
            this.showBigPC = false;
            return;
          };

          this.selectedQuantity = this.sessionData.quantity ? this.sessionData.quantity : 0;
          this.RemainingMenge = Number(this.sessionData.quantity);
          this.stationCount = this.sessionData.stations ? parseInt(this.sessionData.stations) : 1;
          this.postalCodeDetails = this.sessionData.postalCode;
          this.deliveryDates = this.sessionData.desiredDateSettings;
          this.billingAddressEnable = this.sessionData.billingAddressCheck ? this.sessionData.billingAddressCheck : false;
          this.selectedBiilingOption = this.sessionData.selectedBiilingOption ? this.sessionData.selectedBiilingOption : "Einzelrechnungent";
          this.smallPCService.setValueOption(this.selectedBiilingOption);
          if (!this.productInfoForWebPC) {
            let getProdcutInfo = {
              dynamicProductId: this.sessionData.product._id,
              tag: this.sessionData['tag'],
              // all: true
            };
            await this.bigPCService.dynamicProductInfoForWebPc(getProdcutInfo).subscribe((_data: any) => {
              if (_data['success']) {
                this.productInfoForWebPC = _data.data;
                this.thridStagePdfs = this.productInfoForWebPC['pdfs'] || [];
                this.productWebInfoCheck();
              };
            });
          } else {
            this.productWebInfoCheck();
          };
        };
      });
    };

    await this.setFormControls();

    // check for smallpc view
    if ((this.sessionId == "null" || this.sessionId == null) && this.sessionData == null && !this.isLoggedIn && !this.isGuestUser) {
      // show login page here
      this.portalAuthService.logoutUser();
      this.bigPCService.displayLoginPage(true);
      return false;
    };

    if ((!this.currentPage || this.currentPage == "null") && !this.isLoggedIn) {
      this.currentPage = "one";
      let url: string = this.url;
      url = `${url}&stage=one`;
      window.history.replaceState('', document.title, url);
    };

    if (this.currentPage != "one" && this.currentPage != "null") {
      let dependenacyCheck = await this.prepareDependenciesInFirstStage().catch((err: any) => err);
      if (dependenacyCheck) {
        await this.pageIdentify();
      } else {
        this.clickedPage = "page1";
        this.clickedoption = "Bestelloptionen";
      };
    };
    this.checkRatePaymentParams();
  };

  async productWebInfoCheck() {
    // need to get portal user address list if user login in the account
    if (this.portalAuthService.isUserLoggedIn() && !this.portalAuthService.isGuestUser()) {
      this.ifLogdinUsersCase();
    } else {
      this.getProductDatabyUnter();
      this.showBigPC = true;

      await this.dynamicOptionsDataFetch(this.sessionData);
    };
  };

  checkProcutInfo() {
    return Object.keys(this.productInfoForWebPC).length ? true : false;
  };

  primaryAddressData: any;
  async ifLogdinUsersCase() {
    let ifpaymentOptionIsRaten: any = this.sessionData.ratePaymentSettings;

    let getAddressHere = await this.getPortalUserAddressses();
    let sessionExists = await this.smallPCService.validateSessionData(this.sessionId);
    if (!sessionExists) {
      this.showSmallPc = true;
      this.showBigPC = false;
      await this.getProductDatabyUnter();
    } else {
      this.showSmallPc = false;
      this.showBigPC = true;
      await this.getProductDatabyUnter();
      this.dynamicOptionsDataFetch(this.sessionData);
    };
  };

  getCurrentPageInfo() {
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);

    this.sessionId = decodeURIComponent(params.get("sid"));
    this.hostname = decodeURIComponent(params.get("Domainname"));
    this.currentPage = decodeURIComponent(params.get("stage"));
    this.selectedPageName = decodeURIComponent(params.get("page"));
    this.selectedPageName = decodeURIComponent(params.get("page"));
    this.tempOrderId = decodeURIComponent(params.get("tempOrderId"));
  };

  pageIdentify() {
    if (this.currentPage == "two") {
      this.page_1 = this.page_3 = false;
      this.page_2 = true;
      this.clickedPage = "page2";
      this.clickedoption = "Adresseeingebe";
      this.setAddressToForm();

    } else if (this.currentPage == "three") {
      this.page_1 = this.page_2 = false;
      this.page_3 = true;
      this.clickedPage = "page3";
      this.clickedoption = "Bestellübersicht";
    } else {
      this.page_3 = this.page_1 = this.page_2 = false;
      this.clickedPage = "page1";
      this.clickedoption = "Bestelloptionen";
    }
  };

  setAddressToForm() {
    if (this.sessionData?.orderAddresses) {
      let deliveryAddressesList = this.sessionData.orderAddresses.deliveryAddress;
      let billingAddressesList = this.sessionData.orderAddresses.billingAddress;
      this.selectedBiilingOption = this.sessionData.selectedBiilingOption;
      this.billingAddressEnable = this.sessionData.billingAddressCheck;
      if (deliveryAddressesList && deliveryAddressesList.length) {
        this.setExitDelivryAddress(deliveryAddressesList);
      }
      if (billingAddressesList && billingAddressesList.length) {
        this.setExiBillingAddress(billingAddressesList);
      };
    };
  };

  setExitDelivryAddress(delivery) {

    if (delivery.length) {
      delivery.forEach((address, i) => {
        this.stationArray().controls[i]?.get("deliveryAddress").patchValue(address);
      });

      if (this.stationCount == 1) {
        this.totalMenge = this.selectedQuantity;
        this.stationArray().controls[0].get("deliveryAddress").get('quantity').setValue(this.selectedQuantity);
      };
      this.RemainingMenge = Number(this.sessionData.quantity);
      this.quantityCheck();
    };
  };

  setExiBillingAddress(billing) {
    if (billing.length) {
      billing.forEach((address, i) => {
        this.stationArray().controls[i]?.get("billingAddress").patchValue(address);
      });
    };
  };





  prepareDependenciesInFirstStage() {
    return new Promise(async (resolve, reject) => {
      try {
        let sessionInfo: any = await this.gettingNewSessionData(this.sessionId);
        this.sessionData = sessionInfo;
        if (this.sessionData) {
          this.selectedQuality = this.localStorageService.getSingleSessionData('cart');
          this.selectedBiilingOption = this.sessionData.selectedBiilingOption;
        };

        this.smallPCService.setValueOption(this.selectedBiilingOption);
        if (this.sessionData.billingAddressCheck) {
          this.selectedBiilingOption = "Sammelrechnung";
          this.billingAddressEnable = true;
        } else {
          this.selectedBiilingOption = "Einzelrechnungent";
          this.billingAddressEnable = false;
        };

        this.handleDOBCheck();
        this.quantityCheck();
        this.clickedoption = 'Adresseeingebe';
        this.clickedPage = 'page2';
        this.page_2 = true;
        this.page_3 = false;

        this.isLoggedIn = await this.portalAuthService.isUserLoggedIn();
        if (!this.isLoggedIn && this.registration_at_order && !this.isGuestUser) {
          this.bigPCService.displayLoginPage(true);
          this.tempHideSecondStage = true;
        }
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  };

  async validateSessionId() {
    return new Promise(async (resolve, reject) => {
      try {
        let affiliateDetailsExists = await this.orderService.checkForAffliateQueryParams();
        if (affiliateDetailsExists.status) {
          this.affliateDetails = this.orderService.affliateDetails;
          let resp = await this.orderService.affiliateSessionCreation(this.hostname).catch(() => false);
          if (resp && resp.sessionId) {
            this.sessionId = resp.sessionId;
            let sessionInfo: any = await this.gettingNewSessionData(this.sessionId);
            this.sessionData = sessionInfo;
            resolve(true);
          };
        };
        resolve(false);
      } catch (err) {
        reject(err);
      }
    });
  };

  getProductDatabyUnter() {
    return new Promise((resolve, reject) => {
      try {
        this.loader = true;
        this.smallPCService.unterProducts().subscribe((productsData: any) => {
          this.loader = false;
          if (productsData.success) {
            let smallPcData = this.localStorageService.getSessionData(this.sessionId);
            // console.log(smallPcData);

            let dynamicPcDataArr = productsData.data.map(n => { return { ...n, dynamicProductId: n._id } });
            // console.log(dynamicPcDataArr, 5988)
            if (!dynamicPcDataArr.find(id => id._id == smallPcData.product._id)) {
              // console.log("inside loop")
              if (dynamicPcDataArr && dynamicPcDataArr.length) {
                // console.log("inside loop 2")
                smallPcData.product._id = dynamicPcDataArr[0]['_id'];
                smallPcData.product.name = dynamicPcDataArr[0]['appName'];
                this.localStorageService.setSessionData(smallPcData);
                this.sessionData = { ...this.sessionData, ...smallPcData };
                // console.log(this.sessionData, 597)
              }
            }
            // console.log("after modification", smallPcData)
            this.products = dynamicPcDataArr;
          };
          resolve(true);
        },err=> this.loader = false);
      }
      catch (err) {
        this.loader = false;
        reject(err);
      }
    });
  };

  settingPcDataInLocalStorage(optionsData) {

    return new Promise(async (resolve, reject) => {
      try {
        //setting local options initially
        let localOptionSets = [];
        let previousLocalOptionsSets = this.sessionData.localOptions;
        // let dynamicProductId = localStorage.getItem("product");
        optionsData.forEach((newFetchedOptionSet) => {
          let optionData = previousLocalOptionsSets.find(existingLocalOptions => existingLocalOptions.optionSetId == newFetchedOptionSet['refCentralizedOptionSetId']);
          if (optionData && optionData.optionSetId == newFetchedOptionSet.refCentralizedOptionSetId && optionData.dynamicPcOptionsSetId == newFetchedOptionSet._id) {
            return localOptionSets.push(optionData);
          } else {
            let dynamicOptionsObj = newFetchedOptionSet.dynamicOptions[0];
            let dynamicCentralizedOptionsObj = newFetchedOptionSet.dynamicOptions[0]?.centralizedOptions;
            return localOptionSets.push(
              {
                optionSetId: newFetchedOptionSet['refCentralizedOptionSetId'],
                optionId: dynamicOptionsObj['refCentralizedOptionId'],
                sort: dynamicOptionsObj['sortOrder'],
                dynamicPcOptionsSetId: newFetchedOptionSet['_id'],
                lable: newFetchedOptionSet['name'],
                name: dynamicCentralizedOptionsObj['name'],
                DOB: dynamicCentralizedOptionsObj['DOBStatus'] ? true : false,
              }
            );
          };
        });

        if (optionsData.length) {
          let sessionData = localStorage.getItem(this.sessionId);
          sessionData = JSON.parse(sessionData);
          sessionData['localOptions'] = localOptionSets;
          sessionData = JSON.stringify(sessionData);
          localStorage.setItem(this.sessionId, sessionData);
          resolve(true);
        };
      } catch (err) {
        reject(err);
      };
    });
  };

  checkForPlz = (address: string, localOptionPlz: any) => {
    const commonVal = address.replace(/\s/g, '') == localOptionPlz.replace(/\s/g, '');
    if (commonVal) {
      return true;
    };
    return false;
  };

  updateOptionsEvent(object: { event: any, name: any, optionType: any }) {
    if (!this.optionSetEmpty || object.name != 'DYNAMICOPTIONS') {
      this.updateoptions(object.event, object.name, object.optionType);
    };

    // else {
    //   if(object.name == 'POSTALCODE' || object.name == 'PRODUCT') {
    //     this.updateoptions(object.event, object.name, object.optionType);
    //     // changePostalCode
    //   };
    // };
  };

  async updateoptions(event, name, optionType) {
    name = name.trim();
    if (!this.sessionData || !this.sessionData.tag) {
      let sessionInfo: any = await this.gettingNewSessionData(this.sessionId);
      this.sessionData = sessionInfo;
    };

    if (name == 'PRODUCT') {
      this.loader = true;
      // session storage change with new parameters
      let dynamicProductId = {
        dynamicProductId: event,
        all: true,
        colorsTag: this.sessionData.tag || 1,
      };

      this.bigPCService.dynamicProductInfoForWebPc(dynamicProductId).subscribe(async (newProductData: any) => {
        if (newProductData.success) {
          // this.loader = false;

          this.productInfoForWebPC = newProductData.data;
          this.thridStagePdfs = this.productInfoForWebPC.pdfs || [];
          let getAffiliateStatus = await this.orderService.checkForAffliateQueryParams();

          if (getAffiliateStatus) {
            this.updateAffiliateQueryParams('product', this.productInfoForWebPC.articleId);
          }
          let selctedProduct = {
            name: this.productInfoForWebPC?.appName,
            _id: this.productInfoForWebPC?.dynamicProductId
          };
          if (this.sessionData.product['_id'] !== selctedProduct._id) {
            // making cart null after product shift in stage 1
            this.localStorageService.setSingleKeySessionData('cart', []);
            this.selectedQuality = [];
          }

          if (Object.keys(this.productInfoForWebPC).length) {
            this.colors = this.productInfoForWebPC?.colors;
            if (this.colors && this.colors['theme']) {
              this.theme = 'Theme' + this.colors['theme'];
            } else {
              this.theme = 'Theme1';
            }
            this.label = this.productInfoForWebPC?.unitsName;
            this.symbol = this.productInfoForWebPC?.unitsSymbolForPc;
          };

          this.localStorageService.setSingleKeySessionData('product', selctedProduct);
          //fetching postalcode based on change request in product field in the form
          let existedPostalSearchData = `${this.sessionData.postalCode.postalcode} ${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
          let checkExitPostalCode = Object.assign(dynamicProductId, { event: existedPostalSearchData });

          this.bigPCService.postalCode(checkExitPostalCode).subscribe((postalCode: any) => {
            if (postalCode.data) {
              this.pcErrorHandling.postalCodeHandleError = false;
              let sessionData = localStorage.getItem(this.sessionId);
              sessionData = JSON.parse(sessionData);
              this.localSessionUpdatedData = sessionData;
              this.PCOptions = [];
              this.dynamicOptionsDataFetch(sessionData);
            } else {
              // selected postal code not available
              this.loader = false;
              this.pcErrorHandling.postalCodeHandleError = true;
              this.priceCalculationData = [];
              this.localStorageService.setSingleKeySessionData('cart', []);
            } (error) => {
              this.loader = false;
            };
          })

        } else {
          // product data not available
          this.productInfoForWebPC = {};
          this.priceCalculationData = [];
          this.loader = false;
        }
      });
    } else if (name == 'DYNAMICOPTIONS') {
      let setData: any = await this.localStorageService.setLocalOptionSessionData(event);
      if (setData) {
        if (optionType == 7) {
          // console.log(event, 8399999)
          if (event == 'WUNSTERMIN') {
            let getLieferOptions: any = this.PCOptions.filter(option => option.optionType == 7);
            let wunsOptionData;
            if (getLieferOptions.length > 0 && this.sessionData.localOptions) {
              getLieferOptions[0].dynamicOptions.map((option: any) => {
                this.sessionData.localOptions.forEach((localOPtions: any) => {
                  if (option.refCentralizedOptionSetId == localOPtions.optionSetId && option.refCentralizedOptionId == localOPtions.optionId) {
                    wunsOptionData = option;
                  }
                });
              });
            };
            this.getWunsterminOptionsInfo(wunsOptionData);
          } else {
            if (event.name == "Wunschtermin" || event.refName == "Wunschtermin") {
              this.getWunsterminOptionsInfo(event);
            } else {
              this.fromDateSet = '';
              this.toDateSet = '';
              this.lierferOptionDateSpan(event);
            }
          };
        };

        if (event.optionType == 12) {
          console.info("payment option selected");
          // console.log(event);
          this.dob = event.centralizedOptions.DOBStatus ? true : false;
          this.dobText = event.centralizedOptions.displayText;
          // console.log(event.centralizedOptions.paymentOptionLink,886)
          if (event.centralizedOptions.name.trim() == "Ratenzahlung" || (event.centralizedOptions.paymentOptionLink && event.centralizedOptions.paymentOptionLink != 'none')) {
            // console.log("ratenzaung selected");
            this.sessionData = this.localStorageService.getSessionData(this.sessionId);

            if (this.sessionData.stations > 1) {
              this.calculatedQualities = [];
              this.priceCalculationData = [];
              this.sessionData.stations = 1;

              // this.fetchCalculatedQualities();
              let localOptions = this.sessionData.localOptions;
              let _zalungsart = this.PCOptions.filter(option => option.optionType == 12);
              // console.log(_zalungsart,2322)
              let _zalungDefalut = _zalungsart[0].dynamicOptions.filter(option => option.name != "Ratenzahlung" && option.centralizedOptions.paymentOptionLink == 'none');
              // console.log(_zalungDefalut,2323)
              this.sessionData.localOptions = localOptions.map((option: any) => {
                if (/Ratenzahlung/ig.test(option.name)) {
                  option.name = _zalungDefalut[0].name;
                  option.optionId = _zalungDefalut[0].refCentralizedOptionId;
                  option.optionSetId = _zalungDefalut[0].refCentralizedOptionSetId;
                  option.dynamicPcOptionsSetId = _zalungDefalut[0].refOptionSetId,
                    option.DOB = _zalungDefalut[0].centralizedOptions.DOBStatus;
                  option.sort = _zalungDefalut[0].sortOrder;
                  return option
                } else {
                  return option
                }
              });

              this.localStorageService.setSingleKeySessionData('stations', this.sessionData.stations);
              this.localStorageService.setSessionData(this.sessionData);
              this.localSessionUpdatedData = this.sessionData;
              this.fetchCalculatedQualities();
              return
            }

            if ("centralizedOptions" in event && this.sessionData.stations < 2) {
              // console.log(typeof this.ratePayment(event) );
              // console.log(this, 878)
              if (typeof this.ratePaymentFun(event) !== 'function') {
                // console.log("vamsikrishna");
                let selectedQuality = this.localStorageService.getSingleSessionData('cart');
                selectedQuality = selectedQuality && selectedQuality[0];
                let tempPayload = { price: parseFloat(selectedQuality?.gross), identifier: selectedQuality.product.label };
                let payLoad = Array.isArray(tempPayload) ? tempPayload : [tempPayload];
                this.loader = true;
                this.bigPCService.getDefaultPlans({ instalments: payLoad }).subscribe((data) => {
                  if (data.success) {
                    this.loader = false;
                    this.zahlungspans = data.data;
                    let ratesettingObj = {
                      status: false,
                      zahlungspans: data.data,
                    };
                    this.localStorageService.setSingleKeySessionData('ratePaymentSettings', ratesettingObj);
                    // console.log("function not triggered",data);
                    // console.log("ratesettingObj",ratesettingObj);
                    this.notEligibleForRatePayment()
                  } else {
                    this.loader = false;
                    this.zahlungspans = [];
                  };
                });
              } else {
                console.log("secnd case")
                this.ratePaymentFun(event);
              }
            };
          } else {
            console.log("not ratenzaung selected");
            this.sessionData['ratePaymentSettings'] = {
              status: false,
              zahlungRate: '',
              zahlungspans: []
            };
            this.localSessionUpdatedData = this.sessionData;
            this.localStorageService.setSingleKeySessionData("ratePaymentSettings", {
              status: false, zahlungRate: '', zahlungspans: []
            });
            this.fetchCalculatedQualities();
          };
        } else if (event.optionType != 12 && optionType != 7) {
          this.fetchCalculatedQualities();
        };;
      };
    } else if (name == 'QUANTITY') {
      let quantityValue = event;
      this.localStorageService.setSingleKeySessionData('quantity', quantityValue);
      this.sessionData.quantity = quantityValue;
      this.selectedQuantity = quantityValue;

      this.sessionData = JSON.parse(localStorage.getItem(this.sessionId));

      if (this.sessionData.stations > 1) {
        let localOptions = this.sessionData.localOptions;
        let _zalungsart = this.PCOptions.filter(option => option.optionType == 12);
        let _zalungDefalut = _zalungsart[0].dynamicOptions.filter(option => option.name != "Ratenzahlung");
        this.sessionData.localOptions = localOptions.map((option: any) => {
          if (/Ratenzahlung/i.test(option.name)) {
            option.name = _zalungDefalut[0].name;
            option.optionId = _zalungDefalut[0].refCentralizedOptionId;
            option.optionSetId = _zalungDefalut[0].refCentralizedOptionSetId;
            option.dynamicPcOptionsSetId = _zalungDefalut[0].refOptionSetId,
              option.DOB = _zalungDefalut[0].centralizedOptions.DOBStatus;
            option.sort = _zalungDefalut[0].sortOrder;
            return option;
          } else {
            return option;
          };
        });
        this.sessionData['ratePaymentSettings']['status'] = false;
        this.localStorageService.setSingleKeySessionData('localOptions', this.sessionData.localOptions);
        this.localStorageService.setSingleKeySessionData('ratePaymentSettings', this.sessionData['ratePaymentSettings']);
        this.localSessionUpdatedData = this.sessionData;
      };
      // this.fetchCalculatedQualities();

      this.updateAffiliateQueryParams('amount', quantityValue);
      this.sessionData.postalCode = this.postalCodeDetails;
      let min: number = this.productInfoForWebPC?.minAmount;
      let max: number = this.productInfoForWebPC?.maxAmount;
      let inputValue = this.selectedQuantity;
      this.localSessionUpdatedData = this.sessionData;
      if (inputValue <= max && inputValue >= min) {
        this.dynamicOptionsDataFetch(this.sessionData);
      } else {
        this.loader = false;
        this.priceCalculationData = [];
        this.localStorageService.setSingleKeySessionData('cart', []);
      };
    } else if (name == "STATIONS") {
      this.localStorageService.setSingleKeySessionData('stations', event);
      this.sessionData.stations = event;
      this.stationCount = event;
      this.localSessionUpdatedData = this.sessionData;
      if (this.stationCount > 1) {
        let localOptions = this.localSessionUpdatedData.localOptions;
        let _zalungsart = this.PCOptions.filter(option => option.optionType == 12);
        let _zalungDefalut = _zalungsart[0].dynamicOptions.filter(option => option.name != "Ratenzahlung");
        if (_zalungDefalut.length < 1) {
          this.calculatedQualities = [];
          this.priceCalculationData = [];
          return
        }
        this.sessionData.localOptions = localOptions.map((option: any) => {
          if (/Ratenzahlung/i.test(option.name)) {
            option.name = _zalungDefalut[0].name;
            option.optionId = _zalungDefalut[0].refCentralizedOptionId;
            option.optionSetId = _zalungDefalut[0].refCentralizedOptionSetId;
            option.dynamicPcOptionsSetId = _zalungDefalut[0].refOptionSetId,
              option.DOB = _zalungDefalut[0].centralizedOptions.DOBStatus;
            option.sort = _zalungDefalut[0].sortOrder;
            return option
          } else {
            return option
          };
        });
        this.sessionData['ratePaymentSettings']['status'] = false;
        this.localStorageService.setSingleKeySessionData('localOptions', this.sessionData.localOptions);
        this.localStorageService.setSingleKeySessionData('ratePaymentSettings', this.sessionData['ratePaymentSettings']);
        this.billingAddressEnable = false;
        this.localStorageService.setSingleKeySessionData('billingAddressCheck', this.billingAddressEnable);
        this.localStorageService.setSingleKeySessionData('selectedBiilingOption', '');
      };
      this.setFormControls();
      this.updateAffiliateQueryParams('unloadingPoints', this.stationCount);
      this.fetchCalculatedQualities();
    } else if (name == 'CO2COMPENSATE') {
      this.co2CompensateCheck(event);
    } else if (name == "VOUCHER") {
      this.voucherValidate(event);
    } else if (name == "RATEN") {
      let val = event.target.value;
      val = val.includes(":") ? parseInt(val.split(':')[1].trim()) : val;
      this.sessionData['ratePaymentSettings']['zahlungRate'] = val;
      this.localStorageService.setSingleKeySessionData("ratePaymentSettings", this.sessionData['ratePaymentSettings']);
      this.localSessionUpdatedData = this.sessionData;
      this.fetchCalculatedQualities();
    } else if (name == 'POSTALCODE') {
      this.pcErrorHandling.postalCodeHandleError = false;
      this.postalCodeDetails = event;
      this.localStorageService.setSingleKeySessionData("postalCode", this.postalCodeDetails);
      this.PCOptions = [];
      this.sessionData.postalCode = this.postalCodeDetails;
      this.dynamicOptionsDataFetch(this.sessionData);
      this.updateAffiliateQueryParams('zip', this.postalCodeDetails.postalcode);
    } else if (name == 'RATEPAYMENT') {

    };
  };


  co2CompensateCheck(co2) {
    // console.log(co2, 10533);
    let co2CompensateArray = [];
    co2CompensateArray.push(co2);
    if (this.sessionData.co2Compensation.includes(co2)) {
      let co2filterArray = this.sessionData.co2Compensation.filter(e => e != co2);
      // console.log(co2filterArray, 1058);
      this.localStorageService.setSingleKeySessionData('co2Compensation', co2filterArray);
      this.sessionData.co2Compensation = co2filterArray;
      this.localSessionUpdatedData = this.sessionData;
      this.fetchCalculatedQualities();
    } else {
      let preselectedCo2 = this.sessionData.co2Compensation;
      // console.log(preselectedCo2, 1065);
      co2CompensateArray.push(...preselectedCo2);
      this.localStorageService.setSingleKeySessionData('co2Compensation', co2CompensateArray);
      this.sessionData.co2Compensation = co2CompensateArray;
      this.localSessionUpdatedData = this.sessionData;
      this.fetchCalculatedQualities();
    };
  };

  dynamicOptionsDataFetch(sessionData) {
    //dynamic pc options fetching
    this.loader = true;
    // let sendInfoForOptions = {
    //   dynamicProductId: sessionData.product._id,
    //   plzId: sessionData.postalCode.plzId,
    //   quantity: sessionData.quantity
    // };

    this.bigPCService.dynamicOptions().subscribe(async (dynamicOptions: any) => {
      if (dynamicOptions.success) {
        this.loader = false;
        this.PCOptions = [];
        this.optionSetEmpty = false;
        dynamicOptions.data.forEach((optionset: any) => {
          if (optionset.dynamicOptions.length == 0) {
            this.optionSetEmpty = true;
            this.priceCalculationData = [];
            this.checkOptionSet.push({
              noOption: true,
              optionSetName: optionset.name,
              optionType: optionset.optionType,
            });
          };
        });

        if (this.optionSetEmpty) {
          this.PCOptions = dynamicOptions.data;
        } else {
          this.PCOptions = dynamicOptions.data;
          this.setUpLocalData(this.PCOptions);
        };
      } else {
        // getting dynamic options data failed
        this.priceCalculationData = [];
        this.PCOptions = [];
        this.loader = false;
      };
    });
  };

  async setUpLocalData(data) {
    this.PCOptions = data;
    let setlocalData: any = await this.settingPcDataInLocalStorage(this.PCOptions);
    if (setlocalData) {
      let setData: any = localStorage.getItem(this.sessionId);
      this.sessionData = JSON.parse(setData);
      if (this.sessionData) {
        let getLieferOptions: any = this.PCOptions.filter(option => option.optionType == 7);
        let lieferOptionData;
        if (getLieferOptions.length > 0 && this.sessionData.localOptions) {
          getLieferOptions[0].dynamicOptions.map((option: any) => {
            this.sessionData.localOptions.forEach((localOPtions: any) => {
              if (option.refCentralizedOptionSetId == localOPtions.optionSetId && option.refCentralizedOptionId == localOPtions.optionId) {
                lieferOptionData = option;
              }
            });
          });
        };

        let getZulungOptions: any = this.PCOptions.length && this.PCOptions.filter(option => option.optionType == 12);
        let zulungOptionData;
        if (getZulungOptions.length > 0 && this.sessionData.localOptions) {
          getZulungOptions[0].dynamicOptions.map((option: any, index) => {
            this.sessionData.localOptions.forEach((localOPtions: any) => {
              if (option.refCentralizedOptionSetId == localOPtions.optionSetId && option.refCentralizedOptionId == localOPtions.optionId) {
                if (!index && option.centralizedOptions.name != 'Ratenzahlung' || option.centralizedOptions.papaymentOptionLink == 'none') {
                  this.sessionData.ratePaymentSettings = {
                    status: false,
                    zahlungRate: '',
                    zahlungspans: []
                  }
                };
                zulungOptionData = option;
              }
            });
          });
        };

        if (zulungOptionData) {
          this.dob = zulungOptionData.centralizedOptions.DOBStatus ? zulungOptionData.centralizedOptions.DOBStatus : false;
          this.dobText = zulungOptionData.centralizedOptions.displayText ? zulungOptionData.centralizedOptions.displayText : '';
          this.handleDOBCheck();
        };



        if (lieferOptionData) {
          let getdata: any = await this.getHolidaysData();
          let findinlocalData = this.sessionData.localOptions.filter(obj => obj.name == 'Wunschtermin')
          /** !findinlocalData || !this.sessionData.desiredDateSettings */
          // Check if "wunstermin" is not already selected in the desired date settings of the current session
          // If not selected, show a pop-up to the user
          // it'selected alredy additinal calls will be stopped
          if (lieferOptionData.name == "Wunschtermin" && (!findinlocalData || !this.sessionData.desiredDateSettings.wunstermin)) {
            this.getWunsterminOptionsInfo(lieferOptionData);
          } else {
            this.lierferOptionDateSpan(lieferOptionData);
          };
        } else {
          this.deliveryDates.fromDate = '';
          this.deliveryDates.toDate = '';
          this.deliveryDates.range = '';
          this.deliveryDates.rangeAvailable = false;
          this.deliveryDates.wunstermin = false;
          this.deliveryDates.days = 0;
          this.deliveryDates.dayType = '';
          this.fetchCalculatedQualities();
        };
      };
    };
  };

  fetchCalculatedQualities() {
    // if(this.currentPage && !this.currentPage.match(/one/i)) {
    //   return
    // }
    this.loader = true;
    if (this.sessionData.stations > 1 && Boolean(this.sessionData['ratePaymentSettings']['status'])) {
      this.loader = false;
      this.priceCalculationData = [];
      this.calculatedQualities = [];
      this.localSessionUpdatedData = this.sessionData;
      return;
    };
    // console.log(this.sessionData,1107)
    let getSessionData = localStorage.getItem(this.sessionId);
    this.sessionData = JSON.parse(getSessionData);
    let sendInputIds = {
      plzId: this.sessionData.postalCode.plzId,
      dynamicProductId: this.sessionData.product._id,
      localOptions: this.sessionData.localOptions,
      stations: this.sessionData.stations,
      quantity: this.sessionData.quantity,
      co2Compensation: this.sessionData.co2Compensation,
      desiredDate: {
        days: this.sessionData.desiredDateSettings.days,
        fromDate: this.sessionData.desiredDateSettings.fromDate,
        toDate: this.sessionData.desiredDateSettings.toDate
      },
    };

    if (this.sessionData['voucher'] && this.sessionData['voucher']['valid']) {
      sendInputIds = Object.assign(sendInputIds,
        {
          voucherCode: this.sessionData['voucher']['voucherCode'],
          voucherId: this.sessionData['voucher']['voucherId']
        }
      );
    } else {
      this.voucher['valid'] = false;
      this.voucher['voucherCode'] = '';
      this.voucher['voucherId'] = '';
      this.voucher['error'] = false;
    };
    if (this.sessionData['ratePaymentSettings']['status']) {
      sendInputIds = Object.assign(sendInputIds, { zahlungSpan: this.sessionData['ratePaymentSettings']['zahlungRate'] });
    };

    let min: number = this.productInfoForWebPC?.minAmount;
    let max: number = this.productInfoForWebPC?.maxAmount;
    let inputValue = this.selectedQuantity;
    if (inputValue <= max && inputValue >= min) {
      this.bigPCService.calculatedQualities(sendInputIds).subscribe((calculatedQualitiesData) => {
        if (calculatedQualitiesData.success) {
          this.loader = false;
          this.priceCalculationData = calculatedQualitiesData.data;
          this.findAlarmIndex(this.priceCalculationData.length);
          this.voucherCheckKey && this.openVochersCalcPopup();
          this.voucherCheckKey = false;
          this.oldPriceData = this.localStorageService.getSingleSessionData('cart');
          const objIndex = this.priceCalculationData.findIndex(doc => doc.qualities?._id === this.oldPriceData[0]?.qualities?._id);
          if (objIndex > -1) {
            this.oldPriceData = this.localStorageService.setSingleKeySessionData('cart', [this.priceCalculationData[objIndex]]);
          };
          let debugInfo = {
            totalcond: !this.showSmallPc && this.showBigPC && this.sessionData && this.clickedPage == 'page1' && this.products?.length && this.checkProcutInfo(),
            showSmallPc: !this.showSmallPc,
            showBigPC: this.showBigPC,
            sessionData: this.sessionData,
            clickedpage: this.clickedPage,
            products: this.products?.length,
            productInfo: this.checkProcutInfo(),
          }
          // console.log(debugInfo, 1234);

          localStorage.setItem('debugInfo', JSON.stringify(debugInfo))
      
      
        } else {
          this.loader = false;
          this.priceCalculationData = [];
          this.localStorageService.setSingleKeySessionData('cart', []);
        }
      }, (err) => {
        this.loader = false;
      });
    } else {
      this.loader = false;
      this.priceCalculationData = [];
      this.localStorageService.setSingleKeySessionData('cart', []);
    };
  };

  findAlarmIndex(n) {
    if (this.productInfoForWebPC && this.productInfoForWebPC.portalSettings.alaramSettings.position && this.productInfoForWebPC.portalSettings.alaramSettings.position == 0) {
      this.alarmIndex = 0;
    } else if (n >= 2 && this.productInfoForWebPC && this.productInfoForWebPC.portalSettings.alaramSettings.position && this.productInfoForWebPC.portalSettings.alaramSettings.position == 1) {
      this.alarmIndex = 1;
    } else if (n > 2 && this.productInfoForWebPC && this.productInfoForWebPC.portalSettings.alaramSettings.position && this.productInfoForWebPC.portalSettings.alaramSettings.position == 2) {
      this.alarmIndex = n - 1;
    } else if ((n == 1) && this.productInfoForWebPC && this.productInfoForWebPC.portalSettings.alaramSettings.position && (this.productInfoForWebPC.portalSettings.alaramSettings.position == 2 || this.productInfoForWebPC.portalSettings.alaramSettings.position == 1)) {
      this.alarmIndex = 2;
    } else if ((n == 2) && this.productInfoForWebPC && this.productInfoForWebPC.portalSettings.alaramSettings.position && (this.productInfoForWebPC.portalSettings.alaramSettings.position == 2)) {
      this.alarmIndex = 2;
    }
    else {
      this.alarmIndex = 0;
    }
  };

  showPreisweckerEvent(event) {
    let url: string = this.url;
    let paramMap = new URLSearchParams(url);
    let stage = paramMap.get('stage');
    if (stage) {
      url = url.replace(new RegExp(`&stage=${stage}`, 'gi'), '');
    };
    let newUrl = `${url}&clickedPage=pricealarm`;
    window.history.replaceState('', document.title, newUrl);
    if (!this.isLoggedIn) {
      this.bigPCService.loginPage(true);
    } else {
      this.navigateToPage.emit('pricealarm');
    };
  };

  openVochersCalcPopup() {
    let voucherInfoObj = {};
    for (let a = 0; a < this.priceCalculationData.length; a++) {
      if ("voucherInfo" in this.priceCalculationData[a]) {
        voucherInfoObj = this.priceCalculationData[a];
        break
      }
    }
    let dialogRef = this.dialog.open(VoucherNoticeComponent, {
      panelClass: "my-full-screen-dialog",
      width: "500px",
      data: voucherInfoObj
    });
    dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.languageVar = this.languageVar;
  };

  ratePaymentFun(event) {
    // console.log(event, 1010)
    let selectedQuality = this.localStorageService.getSingleSessionData('cart') || [];
    // console.log(selectedQuality, 10110)
    if (selectedQuality.length && Object.keys(selectedQuality[0]).length < 2) {
      // console.log("inside if")
      // console.log(this.priceCalculationData, 1010)
      selectedQuality = this.priceCalculationData;
    }
    selectedQuality = selectedQuality && selectedQuality[0];
    // console.log(selectedQuality, 1010)
    let tempPayload = { price: parseFloat(selectedQuality?.gross), identifier: selectedQuality.product.label };
    let payLoad = Array.isArray(tempPayload) ? tempPayload : [tempPayload];
    this.loader = true;
    this.bigPCService.getDefaultPlans({ instalments: payLoad }).subscribe((data) => {
      if (data.success) {
        this.loader = false;
        this.zahlungspans = data.data;
        let ratesettingObj = {
          status: false,
          zahlungspans: data.data,
        };
        this.localStorageService.setSingleKeySessionData('ratePaymentSettings', ratesettingObj);
        this.notEligibleForRatePayment()
      } else {
        this.loader = false;
        this.zahlungspans = [];
      };
    });
  };


  lierferOptionDateSpan(optionInfo) {
    let lieferOption = {
      lieferOpionId: optionInfo._id
    };
    this.loader = true;
    this.bigPCService.calcuLieferTerminDates(lieferOption).subscribe((deliveryDates: any) => {
      if (deliveryDates.success) {
        // console.log(deliveryDates, 1011110)
        this.loader = false;
        this.formatDateRange(deliveryDates.data);
      } else {
        this.loader = false;
        this.deliveryDates.fromDate = '';
        this.deliveryDates.toDate = '';
        this.deliveryDates.range = '';
        this.deliveryDates.rangeAvailable = false;
        this.deliveryDates.wunstermin = false;
        this.deliveryDates.days = 0;
        this.deliveryDates.dayType = '';
        let sessionData = localStorage.getItem(this.sessionId);
        if (sessionData) {
          sessionData = JSON.parse(sessionData);
          sessionData['desiredDateSettings'] = this.deliveryDates;
          sessionData = JSON.stringify(sessionData);
          localStorage.setItem(this.sessionId, sessionData);
         this.fetchCalculatedQualities();
        }

      }
    }, (err) => {
      this.loader = false;
    });
  };

  getWunsterminOptionsInfo(optionData) {
    let wunsTerminOption = {
      "refCentralizedOptionId": optionData.refCentralizedOptionId,
      "dynamicProductId": optionData.dynamicProductId,
      "refCentralizedOptionSetId": optionData.refCentralizedOptionSetId,
    };

    this.bigPCService.getWunsterminOptionsData(wunsTerminOption).subscribe((wunsTerminInfo: any) => {

      if (wunsTerminInfo.success) {
        this.wunsterminOptionInfo = wunsTerminInfo.data;
        this.openCalenderPopup();
      } else {
        this.wunsterminOptionInfo = [];
      }
    });
  };

  formatDateRange(deliverydates: { fromDate: any; toDate: any; dayType: any }) {
    // console.log(deliverydates, 1010 )
    let datepipe = new DatePipe("de-DE");
    let fromDate;
    let toDate;
    if (deliverydates.dayType == "single") {
      toDate = deliverydates.toDate ? datepipe.transform(deliverydates.toDate, "dd.MM.yyyy") : null;
    } else {
      fromDate = deliverydates.fromDate ? datepipe.transform(deliverydates.fromDate, "dd.MM.yyyy") : null;
      toDate = deliverydates.toDate ? datepipe.transform(deliverydates.toDate, "dd.MM.yyyy") : null;
    };
    // console.log(`%c fromDate ${fromDate}`, 'background: #222; color: #bada55');
    // console.log(`%c toDate ${toDate}`, 'background: #222; color: #bada55');
    if (fromDate && toDate) {
      // console.log(`%c case1212`, 'background: #222; color: #bada55');
      this.deliveryDates = {
        range: fromDate + "-" + toDate,
        rangeAvailable: true,
        fromDate: fromDate,
        toDate: toDate,
        wunstermin: false,
        days: 0,
        dayType: "multiple",
      };
      // this.deliveryDates.range = fromDate + "-" + toDate;
      // this.deliveryDates.rangeAvailable = true;
      // this.deliveryDates.fromDate = fromDate;
      // this.deliveryDates.toDate = toDate;
      // this.deliveryDates.wunstermin = false;
      // this.deliveryDates.days = 0;
      // this.deliveryDates.dayType = "multiple";
    } else if (toDate) {
      // console.log(`%c case23232`, 'background: #222; color: #bada55');
      this.deliveryDates = {
        range: '',
        rangeAvailable: false,
        fromDate: '',
        toDate: toDate,
        wunstermin: false,
        days: 0,
        dayType: "single",
      };

      // this.deliveryDates.fromDate = '';
      // this.deliveryDates.toDate = toDate;
      // this.deliveryDates.range = '';
      // this.deliveryDates.rangeAvailable = false;
      // this.deliveryDates.wunstermin = false;
      // this.deliveryDates.days = 0;
      // this.deliveryDates.dayType = "single";
    } else {
      // console.log(`%c case34343`, 'background: #222; color: #bada55');
      this.deliveryDates = {
        range: '',
        rangeAvailable: false,
        fromDate: '',
        toDate: '',
        wunstermin: false,
        days: 0,
        dayType: "single",
      }
      // this.deliveryDates.fromDate = '';
      // this.deliveryDates.toDate = '';
      // this.deliveryDates.range = '';
      // this.deliveryDates.rangeAvailable = false;
      // this.deliveryDates.wunstermin = false;
      // this.deliveryDates.days = 0;
      // this.deliveryDates.dayType = '';
    };
    // console.log(this.sessionId,1254)
    let sessionData = localStorage.getItem(this.sessionId);


    if (sessionData) {
      sessionData = JSON.parse(sessionData);
      // console.log(this.deliveryDates, 1010)
      // console.log(sessionData, 1011)
      // console.log(this.sessionData, 1012)
      sessionData['desiredDateSettings'] = this.deliveryDates;
      sessionData = JSON.stringify(sessionData);
      localStorage.setItem(this.sessionId, sessionData);
    } else {
      // Handle the case when sessionData is null
      //   console.log('No session data found for id:', this.sessionId);
      // console.log(this.sessionId,1254);
      // localStorage.setItem(this.sessionId, JSON.stringify(this.sessionData));


    }
    this.fetchCalculatedQualities();
  };

  getHolidaysData() {
    return new Promise((resolve, reject) => {
      try {
        this.bigPCService.dynamicPcHolidays().subscribe((holidays: any) => {
          if (holidays.success) {
            this.holiDaysData = holidays['holidays'];
            this.weekOffsData = holidays.weekoffs;
          };
          resolve(true);
        });
      } catch (error) {
        reject(error)
      }
    });

  };

  openCalenderPopup() {
    if (this.holiDaysData.length > 0) {
      let holiDays = this.holiDaysData.length > 0 ? this.holiDaysData.reduce((accum, prev) =>
        ([...accum, { year: prev.YYYY, month: prev.MM, day: prev.DD }]), []
      ) : [];
      let holiDaysDate = holiDays.map((d) => {
        d.holiDayDate = new Date(`${d.year}/${d.month}/${d.day}`);
        d.weekDay = new Date(`${d.year}-${d.month}-${d.day}`).getDay();
        d.dateString = `${d.day}/${d.month}/${d.year}`
        return d;
      });

      let dialogRef = this.dialog.open(DatePickerComponent, {
        // height: "500px",
        data: {
          fromDate: this.fromDateSet,
          toDate: this.toDateSet,
          weekoffs: this.weekOffsData,
          holidays: holiDays,
          wunsterminOptions: this.wunsterminOptionInfo,
        },
        disableClose: true
      });
      dialogRef.componentInstance.colors = this.colors;
      dialogRef.afterClosed().subscribe(async (data: any) => {
        if (data == undefined || data.data.fromDate == undefined || data.data.toDate == undefined) {
          let getLieferOptions: any = this.PCOptions.filter(option => option.optionType == 7);
          await this.localStorageService.setLocalOptionSessionData(getLieferOptions[0].dynamicOptions[0]);
          this.notSelectedOption = true;
          this.fetchCalculatedQualities();
        } else {
          var datepipe = new DatePipe("de-DE");
          this.fromDateSet = datepipe.transform(this.dateChange(data.data.fromDate), 'dd.MM.yyyy');
          this.toDateSet = datepipe.transform(this.dateChange(data.data.toDate), 'dd.MM.yyyy');

          let weekoffs = {
            Mo: (this.weekOffsData.indexOf(0) >= 0 ? false : true),
            Di: (this.weekOffsData.indexOf(1) >= 0 ? false : true),
            Mi: (this.weekOffsData.indexOf(2) >= 0 ? false : true),
            Do: (this.weekOffsData.indexOf(3) >= 0 ? false : true),
            Fr: (this.weekOffsData.indexOf(4) >= 0 ? false : true),
            Sa: (this.weekOffsData.indexOf(5) >= 0 ? false : true),
            So: (this.weekOffsData.indexOf(6) >= 0 ? false : true),
          };


          let weekDays = [
            (weekoffs.So ? 0 : false),
            (weekoffs.Mo ? 1 : false),
            (weekoffs.Di ? 2 : false),
            (weekoffs.Mi ? 3 : false),
            (weekoffs.Do ? 4 : false),
            (weekoffs.Fr ? 5 : false),
            (weekoffs.Sa ? 6 : false),
          ];


          let fromDateSetCheck = this.fromDateSet.split(".");
          let toDateSetCheck = this.toDateSet.split(".");

          let holidaysArray = [];

          holiDaysDate.forEach((h) => {
            let checkHoliday = h.dateString.split("/");
            let from = new Date(fromDateSetCheck[2], parseInt(fromDateSetCheck[1]) - 1, fromDateSetCheck[0]);  // -1 because months are from 0 to 11
            let to = new Date(toDateSetCheck[2], parseInt(toDateSetCheck[1]) - 1, toDateSetCheck[0]);
            let check = new Date(checkHoliday[2], parseInt(checkHoliday[1]) - 1, checkHoliday[0]);
            if (check > from && check < to) {

              holidaysArray.push(check);
            };
          });

          let fromDateCalc = new Date(this.dateChange(this.fromDateSet));
          let toDateCalc = new Date(this.dateChange(this.toDateSet));
          let weekOnDaysArray = [];

          while (fromDateCalc <= toDateCalc) {
            let findWd = weekDays.find(wd => {
              return wd == fromDateCalc.getDay();
            });
            if (findWd) {
              weekOnDaysArray.push(new Date((new Date(fromDateCalc).setHours(0, 0, 0))));
            };
            fromDateCalc.setDate(fromDateCalc.getDate() + 1);
          };

          weekOnDaysArray.map((w, i) => {
            holidaysArray.map((h) => {
              if (w.toString() == h.toString()) {
                weekOnDaysArray.splice(i, 1);
              }
            })
          });

          let wunsDays = weekOnDaysArray.length;
          this.deliveryDates = {
            range: this.fromDateSet + "-" + this.toDateSet,
            rangeAvailable: true,
            fromDate: this.fromDateSet,
            toDate: this.toDateSet,
            wunstermin: true,
            days: wunsDays,
            dayType: "multiple",
          }

          // this.deliveryDates.range = this.fromDateSet + "-" + this.toDateSet;
          // this.deliveryDates.rangeAvailable = true;
          // this.deliveryDates.fromDate = this.fromDateSet;
          // this.deliveryDates.toDate = this.toDateSet;
          // this.deliveryDates.wunstermin = true;
          // this.deliveryDates.days = wunsDays;
          // this.deliveryDates.dayType = "multiple";


          let sessionData = localStorage.getItem(this.sessionId);
          sessionData = JSON.parse(sessionData);
          sessionData['desiredDateSettings'] = this.deliveryDates;
          sessionData = JSON.stringify(sessionData);
          localStorage.setItem(this.sessionId, sessionData);
          this.fetchCalculatedQualities();
        }
      });
    };
  };

  dateChange(defaultDate) {
    const datePatternRegex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
    let date = datePatternRegex.test(defaultDate);
    if (date) {
      let dateSplit = defaultDate.split(".");
      if (parseInt(dateSplit[1]) > 12) {
        return defaultDate;
      } else {
        let date =
          dateSplit[2] +
          "-"
          +
          ("0" + dateSplit[1]).slice(-2)
          +
          "-"
          +
          ("0" + dateSplit[0]).slice(-2);
        return date;
      }
    } else {
      return defaultDate;
    }
  };

  voucherValidate(voucherCode) {
    this.loader = true;
    if (voucherCode) {
      let data = {
        dynamicProductId: this.sessionData.product._id,
        voucherCode: voucherCode
      }
      this.bigPCService.gutscheinData(data).subscribe((data: any) => {
        if (data.success == true) {
          this.url = window.location.href; /*testcalc*/
          let url: string = this.url;
          this.loader = false;
          this.gutscheinInfo = data.data.voucherData;
          this.voucher['valid'] = true;
          this.voucher['voucherCode'] = voucherCode;
          this.voucher['voucherId'] = this.gutscheinInfo._id;
          this.sessionData['voucher'] = this['voucher'];
          this.localStorageService.setSingleKeySessionData('voucher', this.voucher);
          localStorage.setItem(this.sessionId, JSON.stringify(this.sessionData));
          this.sessionData = this.localStorageService.getSessionData(this.sessionId);
          this.fetchCalculatedQualities();
          if (data.message == 'validVoucher' && url.includes("stage=one")) {
            this.voucherCheckKey = true;
          }
        } else {
          this.loader = false;
          this.voucher['valid'] = false;
          this.voucher['voucherCode'] = voucherCode;
          this.voucher['voucherId'] = '';
          this.sessionData['voucher'] = this['voucher'];
          this.localStorageService.setSingleKeySessionData('voucher', this.voucher);
          localStorage.setItem(this.sessionId, JSON.stringify(this.sessionData));
          this.sessionData = this.localStorageService.getSessionData(this.sessionId);
          this.fetchCalculatedQualities();
        }
      });
    } else {
      this.loader = false;
      this.voucher['valid'] = false;
      this.voucher['voucherCode'] = voucherCode;
      this.voucher['voucherId'] = '';
      this.sessionData['voucher'] = this['voucher'];
      this.localStorageService.setSingleKeySessionData('voucher', this.voucher);
      localStorage.setItem(this.sessionId, JSON.stringify(this.sessionData));
      this.sessionData = this.localStorageService.getSessionData(this.sessionId);
      this.fetchCalculatedQualities();
    }
  };

  setRouteByStageNumber(page: "one" | "two" | "three" | "four") {
    return new Promise((resolve, reject) => {
      try {
        let url = window.location.href;
        var params = new URLSearchParams(url);
        this.currentPage = decodeURIComponent(params.get('stage'));
        if (this.currentPage == 'one' && page == 'one') {
          return resolve(true);
        } else if (this.currentPage == 'two' && page == 'two') {
          return resolve(true);
        } else if (this.currentPage == 'three' && page == 'three') {
          return resolve(true);
        } else if (this.currentPage == 'four' && page == 'four') {
          return resolve(true);
        } else {
          window.history.pushState('', document.title, url);
          let newUrl = '';
          if (!url.includes('stage')) {
            newUrl = `${url}&stage=${page}`;
          } else {
            newUrl = url.replace(this.currentPage, page);
          };

          if (this.url.includes("tempOrderId") && page == 'four') {
            let tempOrderId = decodeURIComponent(params.get("tempOrderId"));
            newUrl = newUrl.replace(`&tempOrderId=${tempOrderId}`, "");
          };

          window.history.replaceState('', document.title, newUrl);
          return resolve(true);
        };
      } catch (err) {
        reject(err);
      };
    });
  };

  // Second Stage Work is here //
  async setFormControls() {
    let localData: any = await this.gettingNewSessionData(this.sessionId);
    this.stationCount = parseInt(localData?.stations);
    if (this.stationCount) {
      let stations: FormArray;
      let stationArr = [...Array(this.stationCount)];
      stations = new FormArray([...stationArr.map((item, i) => new FormGroup({
        station: new FormControl(i + 1, [Validators.required]),
        deliveryAddress: this._fb.group(this.addressFormControls('delivery', i)),
        billingAddress: this._fb.group(this.addressFormControls('billing', i)),
      }))] || []);

      this.stationFormGroup.setControl('stations', stations);

      if (this.stationCount == 1) {
        this.totalMenge = this.selectedQuantity;
        this.stationArray().controls[0].get("deliveryAddress").get('quantity').setValue(this.selectedQuantity);
      } else {
        // let mengeHalf = (this.selectedQuantity / 2);
        // this.stationArray().controls[0].get("deliveryAddress").get('quantity').setValue(mengeHalf);
      };


      let ortString = `${this.postalCodeDetails?.city} ${this.postalCodeDetails?.suburb}`;
      let selectedZipcode = {
        zipCode: this.postalCodeDetails?.postalcode,
        city: this.postalCodeDetails?.city,
        lat: this.postalCodeDetails?.latitude,
        lng: this.postalCodeDetails?.longitude,
        suburb: this.postalCodeDetails?.suburb,
        ort: ortString,
        plzId: this.postalCodeDetails?.plzId,
        travelDistance: this.postalCodeDetails?.plzId,
      };

      if (this.isLoggedIn) {
        this.stationArray().controls[0]?.get("deliveryAddress").patchValue(selectedZipcode);
      } else {
        this.stationArray().controls.forEach((form, i) => {
          if (this.productInfoForWebPC.collectiveOrderStatus) {
            form.get("deliveryAddress").patchValue(selectedZipcode);
          } else if (this.productInfoForWebPC.collectiveOrderStatus == false && i == 0) {
            form.get("deliveryAddress").patchValue(selectedZipcode);
          }
        });
      };
      this.handleDOBCheck();
      this.dobRechnungsadresse = this.billingAddressEnable ? true : false;
      this.checkValidationsForBilling(this.billingAddressEnable);
      this.setAddressToForm();
    };
  };

  async setBankDetailsFormControls() {
    // this.bankDetailsForm = this._fb.group(this._fb.group(this.bankDetailsValidation()),);
    this.bankDetailsForm = this._fb.group({
      bankdetails: this._fb.group(this.bankDetailsValidation()),
    });

  };

  addressFormControls(event, index) {
    let controlsObj = {
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],
      "currentTitle": ['', [Validators.required]],
      "streetName": ['', [Validators.required]],
      "houseNo": ['', [Validators.required]],
      "phoneNumber": ['', [Validators.required, Validators.pattern(this.telephonePattern)]],
      "country": ['Germany', [Validators.required]],
      "countryCode": ['DE', [Validators.required]],
      "zipCode": ['', [Validators.required]],
      "ort": ['', [Validators.required]],
      "companyName": [''],
      "addressZusatz": [''],
      "comments": [''],
      "dateOfBirth": [''],
      "plzId": [''],
      "_id": [''],
      "suburb": ['',],
      "lat": [''],
      "lng": [''],
    };
    if (this.productInfoForWebPC.collectiveOrderStatus) {
      controlsObj = Object.assign(controlsObj,
        {

          "plzEnter": [true,],

        }
      );
    } else {

      controlsObj = Object.assign(controlsObj,
        {
          "plzEnter": [index == 0]
        }
      );
    };
    

    if (event == 'billing') {
      controlsObj = Object.assign(controlsObj,
        {
          "biiling": [false, [Validators.required]],
          "eMail": ['', [Validators.required,]],
          "kundenNummer": [''],
          "city": [''],
        }
      );
    } else {
      controlsObj = Object.assign(
        controlsObj,
        {
          "quantity": ['', [Validators.required]],
          "city": ['', [Validators.required]],
          "checkQuantity": [false],
          // "checkRange": [false]
        }
      );

      if (this.isLoggedIn) {
        controlsObj = Object.assign(
          controlsObj,
          {
            "eMail": ['', [Validators.required]],
            "kundenNummer": [''],
          }
        );
      } else {
        controlsObj = Object.assign(
          controlsObj,
          {
            "eMail": ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/), Validators.email]],
            "emailRepeat": ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/), EmailValidator('eMail')]],
          },
        );

        if (index == 0) {
          controlsObj = Object.assign(
            controlsObj,
            {
              "kundenNummer": [''],
            }
          )
        };
      };

      if (index > 0) {
        Object.assign(
          controlsObj,
          {
            wrongPostalCode: [false]
          }
        );
      }
    };

    if (event == 'billing' && index > 0) {
      return {};
    } else {
      return controlsObj;
    };
  };

  bankDetailsValidation() {
    const model = {
      laufzeit: [""],
      kontoinhaber: ["", [Validators.required]],
      monatlichesNettoeinkommen: ["", [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      beschaeftigung: ["", [Validators.required]],
      mobilfunknummer: ["", [Validators.required, Validators.pattern(/01/)]],
      iban: ["", [Validators.required]],
      bic: ["", [Validators.required]],
      bankName: ["", [Validators.required]],
      accountNumber: ["", [Validators.required]],
      bankCode: ["", [Validators.required]],
      easyCreditprivacyApprovalFormCheck: [false, [Validators.requiredTrue]],
      easyCreditprivacyApprovalFormText: ['', [Validators.required]],
    };
    return model;
  };

  rechnungCheckBoxEvent(event) {
    this.rechnungCheckBox(event);
  };

  rechnungtoggleEvent(event) {
    this.rechnungtoggle(event);
  };

  rechnungCheckBox = async (checked: boolean) => {
    this.smallPCService.setValueOption(checked);
    this.billingAddressEnable = !this.billingAddressEnable;
    this.localStorageService.setSingleKeySessionData('billingAddressCheck', this.billingAddressEnable);
    this.localStorageService.setSingleKeySessionData('selectedBiilingOption', '');
    this.sessionData.billingAddressCheck = this.billingAddressEnable;
    this.stationArray().controls[0].get("billingAddress").get('biiling').setValue(this.billingAddressEnable);
    this.sessionData = this.localStorageService.getSessionData(this.sessionId);
    this.checkValidationsForBilling(this.billingAddressEnable);
    if (!this.billingAddressEnable) {
      this.dobRechnungsadresse = false;
    } else {
      this.dobRechnungsadresse = true;
    };
    this.handleDOBCheck();
  };

  rechnungtoggle = async (event: any) => {
    this.smallPCService.setValueOption(event.value);
    this.billingAddressEnable = !this.billingAddressEnable;
    if (!event) {
      this.selectedBiilingOption = "Einzelrechnungent";
      this.billingAddressEnable = false;
    } else {
      this.selectedBiilingOption = "Sammelrechnung";
      this.billingAddressEnable = true;
    };

    if (event.value == "Einzelrechnungent") {
      this.billingAddressEnable = false;
      this.stationArray().controls[0]?.get("billingAddress").reset();
    } else {
      this.stationArray().controls[0]?.get("billingAddress").get('country').setValue('DE');
      this.stationArray().controls[0]?.get("billingAddress").get('countryCode').setValue('Germany');
      let billingAddressesList = this.sessionData?.orderAddresses?.billingAddress;
      if (billingAddressesList && billingAddressesList.length) {
        this.setExiBillingAddress(billingAddressesList);
      };
    };
    this.localStorageService.setSingleKeySessionData('billingAddressCheck', this.billingAddressEnable);
    this.rechnungToggleHandler(event);
  };


  async rechnungToggleHandler(event) {
    this.localStorageService.setSingleKeySessionData('selectedBiilingOption', event.value);
    this.sessionData.selectedBiilingOption = event.value;
    this.checkValidationsForBilling(this.billingAddressEnable);

    if (!this.billingAddressEnable) {
      this.dobRechnungsadresse = false;
    } else {
      this.dobRechnungsadresse = true;
    };

    this.handleDOBCheck();
    this.stationArray().controls[0].get("billingAddress").get('biiling').setValue(this.billingAddressEnable);
    this.sessionData = this.localStorageService.getSessionData(this.sessionId);
  };

  page1Event() {
    this.page1();
  };

  async page1() {
    this.clickedPage = "page1";
    this.clickedoption = "Bestelloptionen";
    this.agbAccepted.first = this.agbAccepted.second = this.agbAccepted.third = false;

    this.scrollHelper.scrollToFirst("scrolltotopprogress");
    await this.setRouteByStageNumber("one");
    setTimeout(() => {
      var errorDivs = document.getElementsByClassName("scrolltotopprogress");
      if (errorDivs.length > 0) {
        errorDivs[0].scrollIntoView({ behavior: "smooth", block: "start" });
        if (window && typeof window.scrollBy === 'function') {
          window.scrollBy(0, -10);
        }
      };
    }, 50);
  };

  page2Event() {
    this.page2();
  };

  async page2() {
    if (this.page_2) {
      this.clickedPage = "page2";
      this.isLoggedIn = this.portalAuthService.isUserLoggedIn();
      if (!this.isLoggedIn && this.registration_at_order && !this.isGuestUser) {
        this.bigPCService.displayLoginPage(true);
        this.tempHideSecondStage = true;
      };
      this.clickedoption = "Adresseeingebe";
      this.agbAccepted.third = this.agbAccepted.first = this.agbAccepted.second = false;
      this.scrollHelper.scrollToFirst("scrolltotopprogress");
      await this.setRouteByStageNumber("two");
      setTimeout(() => {
        var errorDivs = document.getElementsByClassName("scrolltotopprogress");
        if (errorDivs.length > 0) {
          errorDivs[0].scrollIntoView({ behavior: "smooth", block: "start" });
          if (window && typeof window.scrollBy === 'function') {
            window.scrollBy(0, -50);
          }
        }
      }, 50);
    };
  };

  page4() {
    if (this.page_4) {
      this.clickedoption = "Bestätigung";
    }
  };

  async selectedPage(page) {
    console.log(page, 1986)
    let sessionId = this.localStorageService.getSessionId();
    let localOptionData = localStorage.getItem(sessionId);

    if (localOptionData == "null" || localOptionData == null) {
      console.log(sessionId, 1986)
      // window.location.reload();
      let sessionInfo: any = await this.gettingNewSessionData(sessionId);
      this.sessionData = sessionInfo;
      console.log(sessionInfo, 1993)
    }
    // console.log(localOptionData)

    let presentPageNo = parseInt(this.clickedPage.slice(4));
    let clickedPageNo = parseInt(page.slice(4));
    if (clickedPageNo > presentPageNo) {
      return false;
    };

    if (clickedPageNo == 2) {
      this.page_2 = true;
      this.page_3 = this.page_1 = false;
      this.RemainingMenge = Number(this.sessionData.quantity);
      this.setAddressToForm();
    };

    if (clickedPageNo == 1) {
      this.page_1 = true;
      this.page_3 = this.page_2 = false;
    };

    if (clickedPageNo == 3) {
      this.page_3 = true;
      this.page_1 = this.page_2 = false;
    };

    this.clickedPage = page;
    this[page]();
    this.addNewAddress = false;
  };

  geburtstagPopupEvent() {
    this.geburtstagPopup();
  };

  handleDOBCheck() {
    if (this.billingAddressEnable && this.dob && this.dobRechnungsadresse) {
      this.stationArray().controls[0].get("billingAddress")['controls']['dateOfBirth'].setValidators([Validators.required]);
      this.stationArray().controls[0].get("billingAddress")['controls']['dateOfBirth'].updateValueAndValidity();

      this.stationArray().controls.forEach((form, i) => {
        Object.keys(form.get("deliveryAddress")['controls']).forEach(key => {
          let noValidators = ['dateOfBirth'];
          if (noValidators.includes(key)) {
            form.get("deliveryAddress")['controls'][key].clearValidators();
            form.get("deliveryAddress")['controls'][key].updateValueAndValidity();
            form.get("deliveryAddress")['controls'][key].setValue('');
          };
        });
      });

    } else if (this.dob) {
      if (this.isLoggedIn) {
        this.stationArray().controls[0].get("deliveryAddress")['controls']['dateOfBirth'].setValidators([Validators.required]);
        this.stationArray().controls[0].get("deliveryAddress")['controls']['dateOfBirth'].updateValueAndValidity();
      } else {
        this.stationArray().controls.forEach((form, i) => {
          Object.keys(form.get("deliveryAddress")['controls']).forEach(key => {
            let setValidators = ['dateOfBirth'];
            if (setValidators.includes(key)) {
              form.get("deliveryAddress")['controls'][key].setValidators([Validators.required]);
              form.get("deliveryAddress")['controls'][key].updateValueAndValidity();
            };
          });
        });
      }
    } else {
      this.stationArray().controls.forEach((form, i) => {
        Object.keys(form.get("deliveryAddress")['controls']).forEach(key => {
          let noValidators = ['dateOfBirth'];
          if (noValidators.includes(key)) {
            form.get("deliveryAddress")['controls'][key].clearValidators();
            form.get("deliveryAddress")['controls'][key].updateValueAndValidity();
            form.get("deliveryAddress")['controls'][key].setValue('');
          };
        });
      });

      this.stationArray().controls[0].get("billingAddress")['controls']['dateOfBirth'].clearValidators();
      this.stationArray().controls[0].get("billingAddress")['controls']['dateOfBirth'].updateValueAndValidity();
    };
  };

  geburtstagPopup() {
    if (this.dobText && this.dob) {
      let dialogRef = this.dialog.open(GeburtstagInfoComponent, {
        panelClass: "my-full-screen-dialog",
        width: "500px",
      });
      dialogRef.componentInstance.colors = this.colors;
      dialogRef.componentInstance.zahlungsartdata = this.dobText;
      dialogRef.componentInstance.hostname = this.hostname;
    }
  };

  weiterEvent() {
    this.loader = true;
    this.stationArray().controls.forEach((form, i) => {
      Object.keys(form.get("deliveryAddress")['controls']).forEach(field => {
        if (form.get("deliveryAddress").value[`${field}`] == '' || form.get("deliveryAddress").value[`${field}`] == undefined) {
          form.get("deliveryAddress")['controls'][field].setValue('');
          form.get("deliveryAddress")['controls'][field].markAsDirty();
        };
      });

      let checkbillingAdress = this.localStorageService.getSingleSessionData('billingAddressCheck');
      if (i == 0 && checkbillingAdress) {
        Object.keys(form.get("billingAddress")['controls']).forEach(field => {
          if (form.get("billingAddress").value[`${field}`] == '' || form.get("billingAddress").value[`${field}`] == undefined) {
            form.get("billingAddress")['controls'][field].setValue('');
            form.get("billingAddress")['controls'][field].markAsDirty();
          };
        });
      };
    });
    // if (this.stationFormGroup.valid) {
    this.witerWithoutErrors();
    // }
  };


  stationArray(): FormArray {
    return this.stationFormGroup.get('stations') as FormArray;
  };

  async witerWithoutErrors() {

    if (this.isLoggedIn && this.dob) {
      if (this.billingAddressEnable) {
        if (!(this.stationArray().controls[0]?.get("billingAddress").value.dateOfBirth)) {
          this.selectGeburtstagPopup();
          return;
        };
      } else {
        if (!(this.stationArray().controls[0]?.get("deliveryAddress").value.dateOfBirth)) {
          this.selectGeburtstagPopup();
          return;
        };
      };
    };

    this.checkValidationsForBilling(this.billingAddressEnable);
    let checkValidation = await this.secondStageFormValidationMethod();

    let checkEachQuantityOption: any = await this.checkQuantityForEachStation();
    let checkWrongPlz = true;
    if(this.stationCount > 1) {
       let checkPlzHere:any = await this.checkWrongPostalCode();
       checkWrongPlz = !checkPlzHere.falseCases;
    };
   
    this.quantityCheck();
    if (parseInt(this.sessionData.quantity) == this.totalMenge) {
      this.mengecheck = true;
    } else {
      this.mengecheck = false;
    };
    // console.log(this.secondStageFormValidation);
    // console.log(checkValidation)
    // console.log(this.mengecheck)
    // console.log(!checkEachQuantityOption.falseCases);
    console.log(checkWrongPlz)
    if (this.mengecheck && this.secondStageFormValidation && checkValidation && !checkEachQuantityOption.falseCases && checkWrongPlz) {
      this.loader = true
      let sessionInfo: any = await this.gettingNewSessionData(this.sessionId);
      console.log(sessionInfo, 1234)
      this.sessionData = sessionInfo;
      let setLocal = await this.setAddressInLocal();
      this.localSessionUpdatedData = this.sessionData;
      let updatedData = this.localSessionUpdatedData;
      let url = window.location.href;
      let urls: any = localStorage.getItem('domainUrls');
      urls = JSON.parse(urls)
      var params = new URLSearchParams(url);
      let orderIdTemp = decodeURIComponent(params.get('tempOrderId'));
      let sessionId = decodeURIComponent(params.get('sid'));
      let sendData = {
        options: updatedData.localOptions,
        dynamicProductId: updatedData.product._id,
        productId: updatedData.cart[0].qualities._id,
        co2Compensation: updatedData.co2Compensation,
        quantity: updatedData.quantity,
        sessionId: sessionId,
        bigPcUrls: this.getUrls(environment.pricecalc, urls),
        orderInfo: {
          product: updatedData.product.name,
          quality: updatedData.cart[0].product.label,
          units: updatedData.cart[0].units.name,
        },
        stations: updatedData.stations,
        desiredDate: updatedData.desiredDateSettings,
        address: updatedData.postalCode,
        addresses: updatedData.orderAddresses,
        standardPriceNett: updatedData.cart[0].standardPriceNett,
        gross: updatedData.cart[0].grossWithOutFinance || updatedData.cart[0].gross,
        plzId: updatedData.postalCode.plzId,
        billingAddressCheck: updatedData.billingAddressCheck || false,
        test: !environment.testSite_or_thirdPartySites,
        tempSaleId: orderIdTemp ? orderIdTemp : '',
        ratePaymentDetails: this.sessionData.ratePaymentSettings,
        ratePaymentSatus: this.sessionData.ratePaymentSettings.status,
        cart: updatedData.cart,
        tag: updatedData.tag,
        priceCalcUrl: updatedData.priceCalcUrl
      };
      // if (checkRatePayent) {
      // let socketdata = await this.establishSocketConnection();
      // };
      // console.log(updatedData && updatedData.hasOwnProperty('ratePaymentSetting')  && updatedData.ratePaymentSettings.status, 1234)
      // console.log(updatedData.ratePaymentSettings.status, 1234)
      // console.log(this.sessionData.ratePaymentSettings.status, 1234)
      if (this.sessionData.ratePaymentSettings.status) {
        sendData['grossWithFinance'] = parseFloat(updatedData.cart[0].gross);
      }

      // if account is portal, need to send the portal user information as well from here
      let userData = localStorage.getItem('portal_user') || '{}';
      userData = JSON.parse(userData);
      Object.keys(userData).length ? sendData['portalUser'] = userData : "";

      if (this.sessionData['voucher'] && this.sessionData['voucher']['valid']) {
        sendData = Object.assign(sendData,
          {
            voucherCode: this.sessionData['voucher']['voucherCode'] == '' || !this.sessionData['voucher']['voucherCode'] ? undefined : this.sessionData['voucher']['voucherCode'],
            voucherId: this.sessionData['voucher']['voucherId'] == '' || !this.sessionData['voucher']['voucherId'] ? undefined : this.sessionData['voucher']['voucherId']
          }
        );
      } else {
        this.voucher['valid'] = false;
        this.voucher['voucherCode'] = '';
        this.voucher['voucherId'] = '';
        this.voucher['error'] = false;
      };


      if (this.affliateDetails) {
        sendData["affiliateDetails"] = this.affliateDetails;
      };

      // if (checkRatePayent) {
      //   sendData['socketId'] = this.socketId;
      // }
      // console.log(sendData, 1234)


      this.bigPCService.tempOrder(sendData).subscribe(async (secondStage: any) => {
        if (secondStage.success) {
          this.clickedoption = "Bestellübersicht";
          this.third_stage_button = false;
          this.clickedPage = "page3";
          this.page_3 = true;
          this.scrollHelper.scrollToFirst("scrolltotopprogress");
          this.loader = false;

          let newUrl = '';
          if (!url.includes('tempOrderId')) {
            newUrl = `${url}&tempOrderId=${secondStage.data}`;
          } else {
            newUrl = url.replace(orderIdTemp, secondStage.data);
          };
          window.history.replaceState('', document.title, newUrl);

          await this.setRouteByStageNumber("three");
          setTimeout(() => {
            var errorDivs = document.getElementsByClassName("scrolltotopprogress");
            if (errorDivs.length > 0) {
              errorDivs[0].scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
              if (window && typeof window.scrollBy === 'function') {
                window.scrollBy(0, -50);
              }
            };
          }, 50);
        } else if (!secondStage.success && secondStage.msg == 'accountBlocked') {
          if (secondStage.msg == "accountBlocked") {
            this.blockedUser = true;
            this.clickedoption = "Bestätigung";
            this.clickedPage = 'page4';
            this.page_4 = true;
            this.setRouteByStageNumber("four");
            this.loader = false;
            return
          };
        } else if (!secondStage.success && secondStage.code == 403 || secondStage.code == 401 || secondStage.code == 400 || secondStage.code == 500) {
          this.loader = false;
          const dialogRef = this.dialog.open(PaymentErrorComponent, {
            panelClass: 'my-full-screen-dialog',
            width: '500px',
            disableClose: true
          })

          dialogRef.afterClosed().subscribe((res: any) => {

          }, (err) => console.error(err))

        } else {
          this.loader = false
          let msg = secondStage.msg;
          if (Array.isArray(secondStage.constrains.violations)) {
            this.contstrains = true;
            this.error = secondStage.constrains.violations;
          };
        };
      });

      // if (this.sessionData.stations < 2) {
      //   if (this.sessionData.ratePaymentSettings.status) {
      //     this.ratePaymentApproved = await this.validateBankDetails();
      //     if (!this.ratePaymentApproved || this.bankDetailsForm.invalid) {
      //       return false
      //     }
      //   }
      // };

      // this.clickedoption = "Bestellübersicht";
      // this.third_stage_button = false;
      // this.clickedPage = "page3";
      // this.page_3 = true;
      // this.setRouteByStageNumber("three");
      // this.scrollHelper.scrollToFirst("scrolltotopprogress");
      // this.loader = false;
      // setTimeout(() => {
      //   var errorDivs = document.getElementsByClassName(
      //     "scrolltotopprogress"
      //   );
      //   if (errorDivs.length > 0) {
      //     errorDivs[0].scrollIntoView({
      //       behavior: "smooth",
      //       block: "start",
      //     });
      //     window.scrollBy(0, -50);
      //   }
      // }, 50);
    } else {
      let checkkey = this.sessionData?.orderAddresses?.billingAddress[0]?.eMail;
      let billingAddressCheck = this.sessionData.billingAddressCheck;

      if (billingAddressCheck && (checkkey == "" || checkkey == null)) {
        this.billingErr = true;
        setTimeout(() => {
          this.billingErr = false;
        }, 4500);
        this.viewsnack4 = false;
      } else {
        this.billingErr = false;
      };
      this.viewsnack4 = true;
      this.loader = false;
      setTimeout(() => {
        this.viewsnack4 = false;
      }, 4500);
    };
  };

  secondStageFormValidationMethod() {
    // console.log("secondStageFormValidationMethod")
    return new Promise((resolve, reject) => {
      try {
        this.stationArray().controls.forEach((form, i) => {
          // form.get("deliveryAddress").statusChanges.subscribe(status => {
          //   console.log("deliveryAddress status", status);
          // });
          // form.get("billingAddress").statusChanges.subscribe(status => {
          //   // console.log("billingAddress status", status);
          // });
          // console.log(form);
          // console.log(form.get("deliveryAddress").valid);
          // console.log(!form.get("deliveryAddress").errors);
          // console.log(this.stationArray().controls[0].get("billingAddress").valid);
          if (form.get("deliveryAddress").valid && this.stationArray().controls[0].get("billingAddress").valid) {
            this.secondStageFormValidation = true;
            return resolve(this.secondStageFormValidation);
          } else {
            this.secondStageFormValidation = false;
            form.get("deliveryAddress").markAllAsTouched();
            let checkbillingAdress = this.localStorageService.getSingleSessionData('billingAddressCheck');
            if (i == 0 && checkbillingAdress) {
              this.stationArray().controls[0].get("billingAddress").markAllAsTouched();
            };
            return resolve(this.secondStageFormValidation);
          };
        });
      } catch (err) {
        console.log("err")
        reject(err);
      };
    });
  };

  setAddressInLocal() {
    this.deliveryAddress = [];
    this.billingAddress = [];
    this.stationArray().controls.forEach((form, i) => {
      let deliveryAdd = form.get("deliveryAddress").value;
      let collectDeliveryAdd: any = {};
      if (deliveryAdd.firstName || deliveryAdd.lastName || deliveryAdd.eMail) {
        for (let key in deliveryAdd) {
          if (deliveryAdd.hasOwnProperty(key)) {
            collectDeliveryAdd[key] = deliveryAdd[key];
          }
        };
      };

      if (Object.keys(collectDeliveryAdd).length) {
        this.deliveryAddress.push(collectDeliveryAdd);
      }

      if (i == 0) {
        if (this.billingAddressEnable) {
          let billingAdd = form.get("billingAddress").value;
          let collectBillingAdd: any = {};
          for (let key in billingAdd) {
            if (billingAdd.hasOwnProperty(key)) {
              collectBillingAdd[key] = billingAdd[key];
            }
          };
          this.billingAddress.push(collectBillingAdd);
        }
      };
    });

    let collectAllAddress = {
      deliveryAddress: this.deliveryAddress,
      billingAddress: this.billingAddress,
    };

    this.sessionData = this.localStorageService.getSessionData(this.sessionId);
    this.sessionData = {
      ...this.sessionData,
      ...{
        orderAddresses: collectAllAddress
      }
    };
    this.localStorageService.setSessionData(this.sessionData);
  };


  checkValidationsForBilling(billing) {
    if (billing) {
      Object.keys(this.stationArray().controls[0].get("billingAddress")['controls']).forEach(key => {
        let noValidators = ['kundenNummer', 'companyName', 'comments', 'addressZusatz', '_id', 'dateOfBirth', 'suburb', "lat", "lng", "city",];
        if (!noValidators.includes(key)) {
          this.stationArray().controls[0].get("billingAddress")['controls'][key].setValidators([Validators.required]);
          this.stationArray().controls[0].get("billingAddress")['controls'][key].updateValueAndValidity();
        };
      });

      if (!this.isLoggedIn) {
        this.stationArray().controls[0].get("billingAddress")['controls']["plzId"].clearValidators();
        this.stationArray().controls[0].get("billingAddress")['controls']["plzId"].updateValueAndValidity();
      };
    } else {
      Object.keys(this.stationArray().controls[0].get("billingAddress")['controls']).forEach(key => {
        this.stationArray().controls[0].get("billingAddress")['controls'][key].clearValidators();
        this.stationArray().controls[0].get("billingAddress")['controls'][key].updateValueAndValidity();
      });
    };
  };

  quantityCheckEvent() {
    this.quantityCheck();
  };

  async quantityCheck() {
    this.totalMenge = 0;
    if (this.stationCount >= 2) {
      for (var i = 0; i < this.stationArray().length; i++) {
        let stationQuantity = this.stationArray().controls[i].get("deliveryAddress").value.quantity;
        if (this.productInfoForWebPC.minAmount <= stationQuantity) {
          this.totalMenge = this.totalMenge + Number(this.stationArray().controls[i].get("deliveryAddress").value.quantity);
          this.stationArray().controls[i].get('deliveryAddress').get('checkQuantity').setValue(false);
        } else {
          this.totalMenge = this.totalMenge + Number(this.stationArray().controls[i].get("deliveryAddress").value.quantity);
          this.stationArray().controls[i].get('deliveryAddress').get('checkQuantity').setValue(true);
        };
      };
    } else {
      for (var i = 0; i < this.stationArray().length; i++) {
        if (Number(this.stationArray().controls[i].get("deliveryAddress").value.quantity)) {
          this.totalMenge = this.totalMenge + Number(this.stationArray().controls[i].get("deliveryAddress").value.quantity);
        };
      };
    };

    this.RemainingMenge = Number(this.sessionData.quantity) - Number(this.totalMenge);
    if (Number(this.sessionData.quantity) == this.totalMenge && this.RemainingMenge == 0) {
      this.mengecheck = true;
    } else {
      this.mengecheck = false;
    };
  };


  checkQuantityForEachStation() {
    return new Promise((resolve, reject) => {
      try {

        let myTrueCounter = 0;
        let myFalseCounter = 0;

        for (let form of this.stationArray().controls) {
          let eachStationQuantity = form.get('deliveryAddress').value.quantity;
          this.productInfoForWebPC.minAmount <= eachStationQuantity ? myTrueCounter++ : myFalseCounter++;
        };

        return resolve({ trueCases: myTrueCounter, falseCases: myFalseCounter });
      } catch (err) {
        reject(err);
      }
    });
  };

  checkWrongPostalCode() {
    return new Promise((resolve, reject) => {
      try {
        let myTrueCounter = 0;
        let myFalseCounter = 0;

        for (let form of this.stationArray().controls) {
          let eachStationPlz = form.get('deliveryAddress').value.wrongPostalCode;
          eachStationPlz ? myFalseCounter++ : myTrueCounter++;
        };

        return resolve({ trueCases: myTrueCounter, falseCases: myFalseCounter });
      } catch (err) {
        reject(err);
      };
    });
  };



  async page3() {
    if (this.page_3) {
      this.third_stage_button = false;
      this.clickedoption = "Bestellübersicht";
      this.agbAccepted.third = this.agbAccepted.first = this.agbAccepted.second = false;
      this.clickedPage = "page3";
      this.scrollHelper.scrollToFirst("scrolltotopprogress");
      await this.setRouteByStageNumber("three");

      setTimeout(() => {
        var errorDivs = document.getElementsByClassName("scrolltotopprogress");
        if (errorDivs.length > 0) {
          errorDivs[0].scrollIntoView({ behavior: "smooth", block: "start" });
          if (window && typeof window.scrollBy === 'function') {
            window.scrollBy(0, -10);
          }
        }
      }, 50);
    }
  };

  // ratepament options
  notEligibleForRatePayment() {

    this.sessionData = this.localStorageService.getSessionData(this.sessionId);
    // let zahlungSpan = false;
    let dialogRef;
    if (!this.dialogOpened) {
      dialogRef = this.dialog.open(RatePaymentSelectionPopupComponent, {
        panelClass: "my-full-screen-dialog",
        width: "350px",
        data: this.zahlungspans,
      });
      this.dialogOpened = true;
      dialogRef.componentInstance.colors = this.colors;
      dialogRef.afterClosed().subscribe(async (selectedSpan) => {
        this.dialogOpened = false;
        if (!selectedSpan) {
          // console.log("no span selected");
          let paymentData = this.sessionData.localOptions.find((i) => i.name == "Ratenzahlung");

          if (paymentData) {

            let zalungsortOptionSet = this.PCOptions.find((i) => i.refCentralizedOptionSetId == paymentData.optionSetId);
            zalungsortOptionSet = zalungsortOptionSet.dynamicOptions[0];

            this.sessionData.localOptions.map((i) => {
              if (i.name == 'Ratenzahlung') {
                i.optionSetId = zalungsortOptionSet['refCentralizedOptionSetId'];
                i.optionId = zalungsortOptionSet['refCentralizedOptionId'];
                i.sort = zalungsortOptionSet['sortOrder'];
                i.dynamicPcOptionsSetId = zalungsortOptionSet['_id'];
                i.lable = zalungsortOptionSet['refName'];
                i.name = zalungsortOptionSet.centralizedOptions['name'];
              }
            });
          };
          this.localSessionUpdatedData = this.sessionData;
          return;
        };
        // console.log(this.sessionData, 2491);
        // console.log("selected span", selectedSpan);

        this.sessionData['ratePaymentSettings']['zahlungRate'] = selectedSpan;
        this.sessionData['ratePaymentSettings']['status'] = true;
        this.localStorageService.setSingleKeySessionData("ratePaymentSettings", this.sessionData.ratePaymentSettings);
        this.localSessionUpdatedData = this.sessionData;
        this.fetchCalculatedQualities();
        // let zahlungsart = this.options.Zahlungsart.find(
        //   (o) => o.calculation == "standard"
        // );
        // if (zahlungsart) {
        //   this.localoptions.Zahlungsart._id = zahlungsart._id;
        // } else {
        //   this.localoptions.Zahlungsart._id = "5ade00a02cb3a95cca554882";
        // } @vamsikrishna need to check
        // this.ratePayment = false;
        // this.zahlungspans = [];
        // let prevSessionData = await this.SmallPcService.getSessionData(
        //   this.sessionId
        // );
        // let newSessionData = { ...prevSessionData };
        // this.localoptions["zahlungspans"] = this.zahlungspans;
        // newSessionData["localOptions"] = { ...this.localoptions };
        // this.SmallPcService.setSessionData(this.sessionId, newSessionData);
        // this.localoptions.zahlungSpan = null; @vamsikrishna need to check
        // this.zahlungspans = [];
        // this.updateoptions(event, "Zahlungsart");
      });
    }
  };

  bestellenEvent(event) {
    //stage 1 submit button comes here
    this.bestellen(event);
  };

  async bestellen(selectedQuality) {
    console.log(selectedQuality, 2585);
    if (!selectedQuality) {
      //if no product was selected then trying to get the product from localstorage
      let sessionData = await this.localStorageService.getSessionData(this.sessionId);
      selectedQuality = sessionData['cart'];
    }
    if (selectedQuality) {
      this.bestellen_withpoutpopup(selectedQuality);
      this.dataLayer.push({
        'event': 'auprisOrderProdct',
        'priceNet': selectedQuality[0]?.gross,
      });
    } else {
      console.log("no productselected");
    };
  };

  updateUrl(url: string) {
    let params = new URLSearchParams(url);
    if (params.get('Lieferort')) {
      url = url.replace(`&Lieferort=${params.get('Lieferort')}`, '');
      url = url.replace(`Lieferort=${params.get('Lieferort')}`, '');
    }

    if (params.get('Liter')) {
      url = url.replace(`&Liter=${params.get('Liter')}`, '');
      url = url.replace(`Liter=${params.get('Liter')}`, '');
    }

    if (params.get('abladestellen')) {
      url = url.replace(`&abladestellen=${params.get('abladestellen')}`, '');
      url = url.replace(`abladestellen=${params.get('abladestellen')}`, '');
    }

    if (params.get('Product')) {
      let product = params.get('Product');
      product = encodeURIComponent(product);
      url = url.replace(`&Product=${product}`, '');
      url = url.replace(`Product=${product}`, '');
    }

    if (params.get('traderRedirect')) {
      url = url.replace(`&traderRedirect=${params.get('traderRedirect')}`, '');
      url = url.replace(`traderRedirect=${params.get('traderRedirect')}`, '');
    }

    if (params.get('zip')) {
      url = url.replace(`&zip=${params.get('zip')}`, '');
      url = url.replace(`zip=${params.get('zip')}`, '');
    }

    if (params.get('unloadingPoints')) {
      url = url.replace(`&unloadingPoints=${params.get('unloadingPoints')}`, '');
      url = url.replace(`unloadingPoints=${params.get('unloadingPoints')}`, '');
    }

    if (params.get('amount')) {
      url = url.replace(`&amount=${params.get('amount')}`, '');
      url = url.replace(`amount=${params.get('amount')}`, '');
    }

    if (params.get('product')) {
      url = url.replace(`&product=${params.get('product')}`, '');
      url = url.replace(`product=${params.get('product')}`, '');
    }

    if (params.get('consumerId')) {
      url = url.replace(`&consumerId=${params.get('consumerId')}`, '');
      url = url.replace(`consumerId=${params.get('consumerId')}`, '');
    }

    if (params.get('affiliateId')) {
      url = url.replace(`&affiliateId=${params.get('affiliateId')}`, '');
      url = url.replace(`affiliateId=${params.get('affiliateId')}`, '');
    }

    if (params.get('callBackUrl')) {
      url = url.replace(`&callBackUrl=${params.get('callBackUrl')}`, '');
      url = url.replace(`callBackUrl=${params.get('callBackUrl')}`, '');
    }
    window.history.replaceState('', document.title, url);
  };

  createRedirectUrls(tempOrderId?) {
    let urls: any = localStorage.getItem('domainUrls');
    urls = JSON.parse(urls)
    if (urls && Object.keys(urls).length) {
      this.url = window.location.href;
      var params = new URLSearchParams(this.url);
      let sessionId = decodeURIComponent(params.get('sid'));
      let data = {
        "urlSuccess": `${urls.priceCalcUrl}/#&view=bigpc&sid=${sessionId}&stage=three&tempOrderId=${tempOrderId}&callback=true&tempOrderStatus=success`,
        "urlCancellation": `${urls.priceCalcUrl}/#&view=bigpc&sid=${sessionId}&stage=three&tempOrderId=${tempOrderId}&callback=true&tempOrderStatus=cancel`,
        "urlDenial": `${urls.priceCalcUrl}/#&view=bigpc&sid=${sessionId}&stage=three&tempOrderId=${tempOrderId}&callback=true&tempOrderStatus=denial`
      }
      return data;
    } else {
      return {}
    }
  }
  async bestellen_withpoutpopup(selectedQuality) {
    let navigateLoginPage: boolean =true;
    console.log(this.productInfoForWebPC,2741)
    if (this.productInfoForWebPC?.portalSettings?.status) {
      navigateLoginPage = this.productInfoForWebPC?.portalSettings?.position == 2 ? true : false;
    } else {
      navigateLoginPage = false;
    };
    if (environment.testSite_or_thirdPartySites && this.productInfoForWebPC?.portalSettings?.testMode) {
      navigateLoginPage = false;
    }
    let checkPortalUser = localStorage.getItem('portal_user') || '{}';
    checkPortalUser = JSON.parse(checkPortalUser);
    Object.keys(checkPortalUser).length ? this.activeUser = true : this.activeUser = false;
    if (navigateLoginPage && !(this.isLoggedIn || this.isGuestUser)) {
      this.bigPCService.displayLoginPage(true);
    } else if (this.activeUser) {
      console.log("active user moving to next stage 2");
      //active user moving to next stage 2
      this.savePriceEnquiry();
      //active user available moving to next stage 2 and if stage 2 is filled with tempOrderdata compareing temp order data with current data
      this.movingToStage2CheckWithTempOrderId();//recent-changes
      this.nagivateToSecondStage();
    } else {
      console.log("active user not available moving to next stage 2");
      //active user not available moving to next stage 2 and if stage 2 is filled with tempOrderdata compareing temp order data with current data
      this.movingToStage2CheckWithTempOrderId(); //recent-changes
      this.nagivateToSecondStage();
    };


    // this is to change the router state with corresponding stage number
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    window.history.pushState("", document.title, url);
    if (url.includes("stage=one")) {
      url = url.replace("stage=one", "stage=two");
    } else if (url.includes("stage=three")) {
      url = url.replace("stage=three", "stage=two");
    } else if (url.includes("stage=four")) {
      url = url.replace("stage=four", "stage=two");
    } else {

    };

    window.history.replaceState('', document.title, url);
    this.selectedQuality = selectedQuality;
    this.scrollHelper.scrollToFirst("scrolltotopprogress");
    setTimeout(() => {
      var errorDivs = document.getElementsByClassName("scrolltotopprogress");
      if (errorDivs.length > 0) {
        errorDivs[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (window && typeof window.scrollBy === 'function') {
          window.scrollBy(0, -50);
        }
      }
    }, 50);
  };


  nagivateToSecondStage() {
    this.clickedoption = "Adresseeingebe";
    this.clickedPage = "page2";
    this.page_2 = true;
    this.page_3 = this.page_1 = false;
    this.setFormControls();

    let sessionData = this.localStorageService.getSessionData(this.localStorageService.getSessionId());
    let ifpaymentOptionIsRaten: any = sessionData.ratePaymentSettings;
  };


  savePriceEnquiry() {
    this.sessionData = JSON.parse(localStorage.getItem(this.sessionId));
    let sendData = {
      dynamicProductId: this.sessionData?.product?._id,
      plzId: this.sessionData?.postalCode?.plzId,
      zipCode: this.sessionData?.postalCode?.postalcode + " " + this.sessionData?.postalCode.city + " " + this.sessionData?.postalCode.suburb,
      stations: this.sessionData?.stations,
      pcOptions: this.getPcOptions(this.sessionData?.localOptions),
    };

    if (this.sessionData?.cart.length && this.sessionData?.cart[0]) {
      let addonObj = {
        product: [
          {
            "productName": this.sessionData?.cart[0]['product']['label'],
            "quantity": this.sessionData?.quantity,
            "productUnitPrice": this.sessionData?.cart[0]['basicPricePriceFactorNett'],
          }
        ],
        priceDetails: {
          "tax": this.sessionData?.cart[0]['tax'],
          "taxValue": this.sessionData?.cart[0]['productTax']['taxRate'],
          "basicSurgargePriceNett": this.sessionData?.cart[0]['basicSurgargePriceNett'],
          "gross": this.sessionData?.cart[0]['gross']
        },
        currencySymbol: this.sessionData?.cart[0]['currencySymbol']
      };
      Object.assign(sendData, addonObj);
    };
    this.adminService.savePortalOrderInfo(sendData).subscribe(resp => {
      if (resp.success) {

      };
    });
  };

  getPcOptions(localoptions) {
    let data = [];
    localoptions.forEach(obj => {
      let tempObj = {
        optionSet: obj.lable,
        option: obj.name
      }
      data.push(tempObj)
    });
    return data;
  };

  getPortalUserAddressses() {
    return new Promise(async (resolve, reject) => {
      try {
        this.adminService.getPortaladdresses().subscribe(async (result: any) => {
          if (result.success && result.data.length) {
            const allAddresses = [...result.data];
            this.allUserAddress = allAddresses;
            this.billingAddresses = [...result.data];
            let primaryAddresses = allAddresses.reduce((prev, address) => {
              if (address.isPrimary) {
                address.class = 'untick'
                prev = [...prev, address,]
              }
              return prev;
            }, []);

            this.primaryAddresses = [...primaryAddresses].concat([]);
            let secondaryAddresses = allAddresses.reduce((prev, address) => {
              if (!address.isPrimary) {
                address.class = 'untick'
                prev = [...prev, address,]
              }
              return prev
            }, []);
            this.secondaryAddresses = JSON.parse(JSON.stringify([...secondaryAddresses]));
            this.primaryAddresses = JSON.parse(JSON.stringify([...primaryAddresses]));
            resolve(result.data);
          } else {
            resolve(result.data)
          };
        }, (error) => {
          resolve(false);
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  selectGeburtstagPopup() {
    let dialogRef = this.dialog.open(
      GeburtstagSelectonPopupComponent,
      {
        panelClass: 'my-full-screen-dialog',
        width: '500px',
        data: {
          colors: this.colors
        },

      }
    );
    this.dialogOpened = true;
    // dialogRef.componentInstance.colors = this.colors;

    dialogRef.afterClosed().subscribe(async (data) => {
      if (this.billingAddressEnable) {
        this.stationArray().controls[0].get("billingAddress").get('dateOfBirth').setValue(data.Geburtstag);
      } else {
        this.stationArray().controls[0].get("deliveryAddress").get('dateOfBirth').setValue(data.Geburtstag);
      };

      let primaryIndex = this.billingAddresses.findIndex(ba => ba.isPrimary == true);
      if (primaryIndex >= 0) {
        this.billingAddresses[primaryIndex].dateOfBirth = data.Geburtstag;
      };

      if (data.saveGeburtstag) {
        this.adminService.updateAddress(this.stationArray().controls[0]?.get("deliveryAddress").value).subscribe(result => {
          this.loader = false;
        }, error => {
          console.log(error);
        });
      } else {
        this.loader = false;
      };
    });
  };

  changeAddressHandler(event: { addressType: keyof AddressTypes, lieferIndex: any, addressData: any }) {
    if (event.addressType == 'primary') {
      this.openDialog(
        this.allUserAddress.filter(addr => {
          if (addr) {
            let addPostalCode = `${addr.zipCode} ${addr.city} ${addr.suburb}`;
            let localPostalCode = `${this.sessionData.postalCode.postalcode} ${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
            if (this.checkForPlz(addPostalCode, localPostalCode)) {
              let indexOfPrimary = this.primaryAddresses.findIndex(addr => addr.isPrimary);
              let selectedindex = this.primaryAddresses.findIndex(addr => addr.class == 'tick');
              if (selectedindex > 0) {
                this.primaryAddresses[selectedindex].class = 'tick';
              } else if (addr.isPrimary) {
                this.primaryAddresses[indexOfPrimary].class = 'tick';
              }
              return addr;
            };
          }
        }), event);
    } else if (event.addressType == 'secondary') {
      this.openDialog(this.secondaryAddresses, event);
    } else if (event.addressType == 'billing') {
      this.openDialog(this.billingAddresses, event);
    };
  };

  takeExitAddressQuantity: any;

  openDialog = (addresses, event) => {
    let placeName = `${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
    let countryCode = `${this.sessionData.postalCode.postalcode}`;
    const dialogRef = this.dialog.open(
      AddressSelectionComponent,
      {
        width: "500px",
        data: {
          addresses: addresses,
          addressType: event,
          liferrort: `${countryCode} ${placeName}`,
          languageData: this.languageData,
          colors: this.colors,
        },
        scrollStrategy: this.sso.noop()
      });
    dialogRef.afterClosed().subscribe((data:
      {
        address: UserAddress,
        addressType: {
          addressType: keyof AddressTypes,
          lieferIndex: any
        },
        addNew: boolean,
        index: number
      }
    ) => {
      if (data) {
        this.addNewAddress = data.addNew;
        switch (data.addressType.addressType) {
          case "billing":
            // open create add
            if (data.addNew) {
              this.editAddress = false;
              this.addressType = data.addressType.addressType;
              this.lieferIndex = data.addressType.lieferIndex;
              this.addNewAddress = data.addNew;
              this.stationArray().controls[0]?.get("billingAddress").reset();
            } else {
              this.rechnungadresseHandler(data.address);
            }
            break;
          case "primary":
            if (data.addNew) {
              this.editAddress = false;
              this.addressType = data.addressType.addressType;
              this.lieferIndex = data.addressType.lieferIndex;
              this.addNewAddress = data.addNew;
              this.stationArray().controls[0]?.get("deliveryAddress").reset();
            } else {
              this.primaryAddresses.map((addr, index) => {
                if (index == data.index) {
                  addr.class = 'tick'
                } else {
                  addr.class = 'untick'
                }
              })
              this.takeExitAddressQuantity = this.stationArray().controls[0].get("deliveryAddress").value.quantity;
              this.primaryAddressHandler(data.address);
            }
            break;
          case "secondary":
            if (data.addNew) {
              this.editAddress = false;
              this.addressType = data.addressType.addressType;
              this.lieferIndex = data.addressType.lieferIndex;
              this.addNewAddress = data.addNew;
            } else {
              this.takeExitAddressQuantity = this.stationArray().controls[event.lieferIndex].get("deliveryAddress").value.quantity;
              this.secondaryAddressesHandler(data.address, event.lieferIndex);
            }
            break;
          default:
            break;
        };
      }
    })
  };

  rechnungadresseHandler = (item: UserAddress) => {
    let markTick = this.billingAddresses.find(adr => adr._id == item._id);
    if (markTick) {
      markTick.class = 'tick'
    };

    let previousSelectedId = this.stationArray().controls[0]?.get('billingAddress').value['_id'];
    if (previousSelectedId) {
      let index = this.billingAddresses.findIndex(addr => addr._id == previousSelectedId);
      if (index >= 0) {
        this.billingAddresses[index].class = 'tick';
      };

      this.billingAddresses.map(addr => {
        if (addr._id == item._id) {
          addr.class = "tick";
        } else {
          addr.class = "untick";
        }
      });
    };

    let prepareForAppend = this.prepareLieferFormData(item);

    prepareForAppend.biiling = true;
    let postalCode = `${item.zipCode}`;
    let ortString = `${item.city} ${item.suburb}`;

    prepareForAppend['kundenNummer'] = item.kundenNummer ? item.kundenNummer : '';
    prepareForAppend['comments'] = item.comments ? item.comments : '';
    prepareForAppend['kundenKontoId'] = item.kundenKontoId ? item.kundenKontoId : '';
    prepareForAppend['onlineId'] = item.onlineId ? item.onlineId : '';

    prepareForAppend['eMail'] = item.eMail ? item.eMail : '';

    prepareForAppend['zipCode'] = postalCode ? postalCode : '';
    prepareForAppend['ort'] = ortString ? ortString : '';
    prepareForAppend['city'] = item.city ? item.city : '';
    prepareForAppend['suburb'] = item.suburb ? item.suburb : '';

    prepareForAppend['country'] = item.country ? item.country : 'Germany';
    prepareForAppend['countryCode'] = item.countryCode ? item.countryCode : 'DE';
    if (prepareForAppend.dateOfBirth) {
      let takeDate = new Date(prepareForAppend.dateOfBirth);
      let takeMonth = takeDate.getMonth() + 1;
      let takeDay = takeDate.getDate();
      let takeYear = takeDate.getFullYear();
      let date = takeYear + '-' + ('0' + takeMonth).slice(-2) + '-' + ('0' + takeDay).slice(-2);
      prepareForAppend.dateOfBirth = date;
    };

    this.stationArray().controls[0]?.get("billingAddress").patchValue(prepareForAppend);
    this.setAddressInLocal();
  };
  ratePayment;
  primaryAddressHandler = async (item: UserAddress) => {
    // console.log(item)
    let sessionData = await this.localStorageService.getSessionData(this.sessionId);
    this.ratePayment = sessionData.ratePayment;
    this.previousSelectedId = this.stationArray().controls[0]?.get('deliveryAddress').value['_id'];
    let prepareForAppend = this.prepareLieferFormData(item);
    if (this.takeExitAddressQuantity) {
      prepareForAppend['quantity'] = this.takeExitAddressQuantity;
    } else {
      if (this.stationCount == 1) {
        prepareForAppend['quantity'] = this.selectedQuantity;
      } else {
        if (this.stationCount >= 2 && item.quantity) {
          prepareForAppend['quantity'] = item.quantity;
        } else {
          prepareForAppend['quantity'] = 0;
        };
      };
    };


    let postalCode = `${item.zipCode}`;
    let ortString = `${item.city} ${item.suburb}`;

    prepareForAppend['currentTitle'] = item.currentTitle ? item.currentTitle : '';
    prepareForAppend['kundenNummer'] = item.kundenNummer ? item.kundenNummer : '';
    prepareForAppend['comments'] = item.comments ? item.comments : '';
    prepareForAppend['kundenKontoId'] = item.kundenKontoId ? item.kundenKontoId : '';
    prepareForAppend['onlineId'] = item.onlineId ? item.onlineId : '';

    prepareForAppend['eMail'] = item.eMail ? item.eMail : '';
    prepareForAppend['emailRepeat'] = item.eMail ? item.eMail : '';

    prepareForAppend['zipCode'] = postalCode ? postalCode : '';
    prepareForAppend['ort'] = ortString ? ortString : '';
    prepareForAppend['city'] = item.city ? item.city : '';
    prepareForAppend['suburb'] = item.suburb ? item.suburb : '';
    prepareForAppend['plzId'] = item.plzId ? item.plzId : '';

    prepareForAppend['country'] = item.country ? item.country : 'Germany';
    prepareForAppend['countryCode'] = item.countryCode ? item.countryCode : 'DE';

    prepareForAppend['accountType'] = item.accountType ? item.accountType : '';
    if (prepareForAppend.dateOfBirth) {
      let takeDate = new Date(prepareForAppend.dateOfBirth);
      let takeMonth = takeDate.getMonth() + 1;
      let takeDay = takeDate.getDate();
      let takeYear = takeDate.getFullYear();
      let date = takeYear + '-' + ('0' + takeMonth).slice(-2) + '-' + ('0' + takeDay).slice(-2);
      prepareForAppend.dateOfBirth = date;
    };

    // console.log(prepareForAppend)

    this.stationArray().controls[0]?.get("deliveryAddress").patchValue(prepareForAppend);
    this.quantityCheck();
    this.setAddressInLocal();
  };

  secondaryAddressesHandler = (item: UserAddress, addIndex: any) => {
    let prepareForAppend = this.prepareLieferFormData(item);

    let postalCode = `${item.zipCode}`;
    let ortString = `${item.city} ${item.suburb}`;

    let dupSecondaryAddresses = [...this.secondaryAddresses];

    this.secondaryAddresses = [...dupSecondaryAddresses];

    prepareForAppend['currentTitle'] = item.currentTitle ? item.currentTitle : '';
    prepareForAppend['kundenNummer'] = item.kundenNummer ? item.kundenNummer : '';
    prepareForAppend['comments'] = item.comments ? item.comments : '';
    prepareForAppend['kundenKontoId'] = item.kundenKontoId ? item.kundenKontoId : '';
    prepareForAppend['onlineId'] = item.onlineId ? item.onlineId : '';

    prepareForAppend['eMail'] = item.eMail ? item.eMail : '';
    prepareForAppend['emailRepeat'] = item.eMail ? item.eMail : '';

    prepareForAppend['zipCode'] = postalCode ? postalCode : '';
    prepareForAppend['ort'] = ortString ? ortString : '';
    prepareForAppend['city'] = item.city ? item.city : '';
    prepareForAppend['suburb'] = item.suburb ? item.suburb : '';
    prepareForAppend['plzId'] = item.plzId ? item.plzId : '';


    prepareForAppend['country'] = item.country ? item.country : 'Germany';
    prepareForAppend['countryCode'] = item.countryCode ? item.countryCode : 'DE';

    prepareForAppend['accountType'] = item.accountType ? item.accountType : '';

    if (prepareForAppend.dateOfBirth) {
      let takeDate = new Date(prepareForAppend.dateOfBirth);
      let takeMonth = takeDate.getMonth() + 1;
      let takeDay = takeDate.getDate();
      let takeYear = takeDate.getFullYear();
      let date = takeYear + '-' + ('0' + takeMonth).slice(-2) + '-' + ('0' + takeDay).slice(-2);
      prepareForAppend.dateOfBirth = date;
    };

    this.stationArray().controls[addIndex]?.get("deliveryAddress").patchValue(prepareForAppend);
    this.quantityCheck();
    this.setAddressInLocal();
  };

  async setDefaultAddressesHandler(localAddresses) {

    this.sessionData = this.localStorageService.getSessionData(this.sessionId);
    let addresseList = this.sessionData.orderAddresses;
    if (addresseList.deliveryAddress.length > 0) {
      let addPostalCode = `${addresseList.deliveryAddress[0].zipCode} ${addresseList.deliveryAddress[0].city} ${addresseList.deliveryAddress[0].suburb}`;
      let localPostalCode = `${this.sessionData.postalCode.postalcode} ${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
      if (this.checkForPlz(addPostalCode, localPostalCode)) {
        this.primaryAddressHandler(addresseList.deliveryAddress[0]);
      } else {
        let checkExitAdd = this.allUserAddress.find(e => (`${e.zipCode} ${e.city} ${e.suburb}`).replace(/\s/g, '') == localPostalCode.replace(/\s/g, ''));
        if (checkExitAdd) {
          this.primaryAddressHandler(checkExitAdd);
        } else {
          let placeName = `${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
          let countryCode = `${this.sessionData.postalCode.postalcode}`;
          this.editAddress = false;
          this.addressType = 'primary';
          this.lieferIndex = null;
          this.addNewAddress = true;
          if (!this.billingAddressEnable) {
            // this.setQuantityBasedOnStations();
            this.stationArray().controls[0].get("deliveryAddress").get('zipCode').setValue(`${countryCode}`);
            this.stationArray().controls[0].get("deliveryAddress").get('ort').setValue(`${placeName}`);
          } else {
            this.stationArray().controls[0]?.get("billingAddress").reset();
          };
        };
      };
      for (let index = 1; index < this.stationCount; index++) {
        let secondaryAddress = addresseList.deliveryAddress[index];
        if (secondaryAddress) {
          if (('zipCode' in secondaryAddress)) {
            this.secondaryAddressesHandler(secondaryAddress, index);
          };
        }
      };
    };
    if (addresseList.billingAddress.length > 0) {
      if (('zipCode' in addresseList.billingAddress[0]) && this.billingAddressEnable) {
        this.rechnungadresseHandler(addresseList.billingAddress[0]);
      }
    };
  };

  setQuantityBasedOnStations() {
    if (this.stationCount == 1) {
      this.stationArray().controls[0]?.get("deliveryAddress")?.get('quantity').setValue(this.selectedQuantity);
    } else {
      // let mengeHalf = (this.selectedQuantity / 2);
      // this.stationArray().controls[0].get("deliveryAddress").get('quantity').setValue(mengeHalf);
      // this.quantityCheckEvent();
    };
  };

  addNewAddressCancel(event) {
    this.addNewAddress = event.newAdd;
    if (event.addressType == 'primary') {
      let plzData = `${this.sessionData.postalCode.postalcode} ${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
      this.stationArray().controls[0]?.get("deliveryAddress").reset();

      if (this.stationCount == 1) {
        this.stationArray().controls[0].get("deliveryAddress").get('quantity').setValue(this.selectedQuantity);
      }
      this.stationArray().controls[0].get("deliveryAddress").get('ort').setValue(`${plzData}`);
    } else if (event.addressType == 'secondary') {
      this.stationArray().controls[event.index]?.get("deliveryAddress").reset();
    };
    this.setAddressInLocal();
  };

  async addAdressStagetwoHandler(event) {
    let getAdd = await this.getPortalUserAddressses();
    switch (event.addressType) {
      case "billing":
        this.rechnungadresseHandler(event.address);
        if (event.changeRemaining) {
          this.billingAddresses = [...this.billingAddresses, event.address]
          this.getMatchedAddrIndex(this.secondaryAddresses, event.address, 'secondary');
          this.getMatchedAddrIndex(this.primaryAddresses, event.address, 'primary');
          if (this.stationArray().length > 0) {
            let index;
            this.stationArray().controls.forEach((form, i) => {
              if (form.get("deliveryAddress").value._id == event.address._id) {
                index = i;
              }
            });
            if (index >= 0) {
              this.secondaryAddressesHandler(event.address, index)
            }
          }
        }
        break;
      case "primary":
        this.primaryAddressHandler(event.address);
        if (event.changeRemaining) {
          event.address = { ...event.address, class: 'tick' }
          this.primaryAddresses.map(addr => {
            addr.class = 'untick'
          });
          let primaryAdd = [...this.primaryAddresses];
          primaryAdd.push(event.address);
          this.primaryAddresses = [...primaryAdd];
          this.primaryAddresses = [...this.primaryAddresses]
          this.getMatchedAddrIndex(this.billingAddresses, event.address, 'billing');
          this.getMatchedAddrIndex(this.secondaryAddresses, event.address, 'secondary');
          if (this.stationArray().controls[0]?.get('billingAddress').valid) {
            this.updatingMatchedBillingAddr(event.address);
          };
        };
        break;
      case "secondary":
        this.secondaryAddressesHandler(event.address, event.lieferIndex);
        if (event.changeRemaining) {
          this.secondaryAddresses = [...this.secondaryAddresses, event.address]
          this.getMatchedAddrIndex(this.billingAddresses, event.address, 'billing');
          this.getMatchedAddrIndex(this.primaryAddresses, event.address, 'primary')
          if (this.stationArray().controls[0]?.get('billingAddress').valid) {
            this.updatingMatchedBillingAddr(event.address);
          };
        };
        break;
      default:
        break;
    };
  };

  getMatchedAddrIndex(addrArray, newaddress: UserAddress, addressType) {
    let index = addrArray.findIndex((doc) => doc._id == newaddress._id);
    if (index >= 0) {
      if (addressType == 'billing') {
        this.billingAddresses[index] = newaddress;
      } else if (addressType == 'secondary') {
        this.secondaryAddresses[index] = newaddress;
      } else if (addressType == 'primary') {
        this.primaryAddresses[index] = newaddress;
      };
    };
  };

  updatingMatchedBillingAddr(addr) {
    if (addr._id == this.stationArray().controls[0]?.get('billingAddress').value['_id']) {
      this.rechnungadresseHandler(addr);
    }
  };

  prepareLieferFormData = (addressObj): UserAddress => {
    let patchValue = {
      ...addressObj,
    };
    delete patchValue['myMasterId'];
    delete patchValue['unternehmenId'];
    return patchValue;
  };

  downloadFileEvent(event) {
    this.downloadFile(event);
  };

  downloadFile(data) {
    let object = { pdfId: data };
    if (this.disableAnchor) {
      return false;
    }
    this.disableAnchor = true;

    this.bigPCService.pdfiledownload(object, this.hostname).subscribe(
      (data123: any) => {
        this.disableAnchor = false;
        if (data123.sucess) {
          var pdfdata = data123.data.data.data;
          var byteCharacters = atob(pdfdata);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let fileName = `${data}.pdf`;

          if (data123.data.data.name) {
            if (fileName.includes("pdf")) {
              fileName = data123.data.data.name;
            }
          }
          var byteArray = new Uint8Array(byteNumbers);

          let newBlob = new Blob([byteArray], { type: "application/pdf" });
          let bloburl = URL.createObjectURL(newBlob);
          // if (navigator.userAgent.indexOf("Firefox") != -1) {
          //   // importedSaveAs(blob, pdfdata);
          //   return false
          // }
          let ua = window.navigator.userAgent;
          let msie = ua.indexOf("MSIE ");
          if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer
            FileSaver.saveAs(newBlob, `${fileName}`);
            return false;
          }
          if (!!navigator.userAgent.match(/Edge/)) {
            // If edge Explorer
            FileSaver.saveAs(newBlob, `${fileName}`);
            return false;
          }
          try {
            let win = window.open("about:blank", "_blank"); // window.open(bloburl, '_blank')
            win.document.write(
              '<iframe src="' +
              bloburl +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            );
            win.document.title = fileName;
            win.document.close();

            if (!win || win.closed || typeof win.closed == "undefined") {
              FileSaver.saveAs(newBlob, `${fileName}`);
              return false;
            }
          } catch (error) {
            FileSaver.saveAs(newBlob, `${fileName}`);
          }
        } else {
          this.viewsnack2 = true;
          setTimeout(() => {
            this.viewsnack2 = false;
          }, 4500);
        }
      }, () => {

      }
    );
  };

  zahlungMail() {
    if (this.affliateDetails) {
      let object = {};
      object["affiliateDetails"] = this.affliateDetails;
    };
  };

  Geburtstag_popupEvent() {
    this.Geburtstag_popup();
  };

  Geburtstag_popup() {
    if (this.dobText) {
      let dialogRef = this.dialog.open(GeburtstagInfoComponent, {
        panelClass: "my-full-screen-dialog",
        width: "500px",
      });
      dialogRef.componentInstance.colors = this.colors;
      dialogRef.componentInstance.zahlungsartdata = this.dobText;
      dialogRef.componentInstance.hostname = this.hostname;
    };
  };

  // resendMtanEvent(event) {
  //   let params = { tbVorgangskennung: event };
  //   this.bigPCService.mTanResend(params).subscribe(() => {
  //   })
  // };

  priceChangeAcceptEvent(event) {
    this.priceChangeAccept(event);
  };

  priceChangeAccept(data) {
    // console.log(data);
    this.pricChangeAccept = data.target.checked;
    if (this.pricChangeAccept) {

      let newSelected = this.priceChangedData[0];
      this.oldPricesCart = this.priceChangedData[0];
      const objIndex = this.priceCalculationData.findIndex(doc => doc.qualities?._id === newSelected?.qualities?._id);
      // console.log(objIndex, 3391);
      if (objIndex > -1) {
        newSelected['priceChangeAccepted'] = true;
        this.priceCalculationData[objIndex] = newSelected;
        this.localStorageService.setSingleKeySessionData('cart', this.priceChangedData);
      };
    } else {
      this.oldPricesCart = [];
      let oldSelected = this.oldPriceData[0];
      const objIndex = this.priceCalculationData.findIndex(doc => doc.qualities?._id === oldSelected?.qualities?._id);
      if (objIndex > -1) {
        oldSelected['priceChangeAccepted'] = false;
        this.priceCalculationData[objIndex] = oldSelected;
        this.localStorageService.setSingleKeySessionData('cart', this.oldPriceData);
      };
    };
  };

  productTaxAcceptEvent(event) {

  };

  technicalTransectionIdFromEasyCredit: any;
  async ZahlungMailEvent() {
    // console.log("ZahlungMailEvent",this.sessionData);
    // console.log(this.orderStatus,3410);
    // console.log(this.sessionData,3417)
    if ((this.sessionData.ratePaymentSettings.status && this.orderStatus == '') || (this.sessionData.ratePaymentSettings.status && this.orderStatus == '' && this.priceChangewithPaymentMode)) {
      // alert("order process for first time");
      let url = window.location.href;
      var params = new URLSearchParams(url);
      let orderIdTemp = decodeURIComponent(params.get('tempOrderId'));
      let sendTempId = { tempSaleId: orderIdTemp };
      this.sessionData = this.localStorageService.getSessionData(this.sessionId);
      this.sessionData = {
        ...this.sessionData,
        ...{ pdfData: this.thridStagePdfs },
      };
      this.localStorageService.setSessionData(this.sessionData);
      this.loader = true;

      this.bigPCService.goForInstallmentPurchase(sendTempId).subscribe(async (checkResult: any) => {
        // console.log(checkResult, 3622)
        if (checkResult.success) {
          this.loader = false;
          // let sessionda = await this.establishSocketConnection();
          // alert(checkResult.data.technicalTransactionId);
          //paymentBody
          let technicalTransactionId = checkResult.data.technicalTransactionId


          if (this.priceChangewithPaymentMode) {
            technicalTransactionId = this.paymentProcessRetry['technicalTransactionId']
          }
          window.open(`https://ratenkauf.easycredit.de/app/payment/${technicalTransactionId}/finanzierungsvorgaben`, '_self');
          // window.open(`https://ratenkauf.easycredit.de/api/payment/v3/transaction/${checkResult.data.technicalTransactionId}/authorization`, '_self'); //new final order url 
        } else {
          this.loader = false;
        };

      });
    } else if (this.sessionData.ratePaymentSettings.status && this.orderStatus == 'success') {
      // alert("payment final authrization process");
      let url = window.location.href;
      var params = new URLSearchParams(url);
      let orderIdTemp = decodeURIComponent(params.get('tempOrderId'));
      this.finalOrderPlace();
      // this.bigPCService.payAuthorize({id:orderIdTemp}).subscribe((data: any) => {
      //   if(data.success){
      //     this.finalOrderPlace();
      //   } else{
      //     alert("payment failer")
      //            const dialogRef = this.dialog.open(
      //             RatePaymentRejectedInfoComponent, {
      //             panelClass: "my-full-screen-dialog",
      //             width: "500px",
      //             data: event,
      //           });
      //           dialogRef.afterClosed().subscribe((popup) => {

      //           });
      //   }
      // }, (error) => {
      //   console.log(error);
      //   const dialogRef = this.dialog.open(
      //     RatePaymentRejectedInfoComponent, {
      //     panelClass: "my-full-screen-dialog",
      //     width: "500px",
      //     data: event,
      //   });
      //   dialogRef.afterClosed().subscribe((popup) => {

      //   });
      // })
    } else if (this.sessionData && (!this.sessionData.hasOwnProperty("ratePaymentSettings") || !this.sessionData.ratePaymentSettings['status'])) {
      // console.log("manual final order process");
      // alert("manual order process");
      // let checkValidation = await this.secondStageFormValidationMethod();
      // console.log(checkValidation, 3736666);
      // if (!checkValidation) {
      //   this.page_2 = true;
      //   this.page_3 = false;
      //   this.selectedPage("page2");
      //   return false;
      // } else {
      this.finalOrderPlace();
      // };
    };
  };

  priceChangedData: any;
  oldPriceData: any;

  async finalOrderPlace() {
    // console.log("final order place",new Error().stack);
    let sessionInfo: any = await this.gettingNewSessionData(this.sessionId);
    this.sessionData = sessionInfo;
    this.localSessionUpdatedData = this.sessionData;
    let updatedData = this.localSessionUpdatedData;
    let mandatoryCheck = this.thridStagePdfs;
    let mandatory: any;
    mandatory = mandatoryCheck.findIndex((data) => data.mandatory_checkbox && !data.checked);
    this.mandatoryCheckIndex = mandatory;
    let pdfData = this.thridStagePdfs.reduce((prev, pdf) => {
      if (pdf._id && pdf.checkbox_in_the_pricecalculator) {
        prev = [
          ...prev,
          {
            _id: pdf._id,
            name: pdf.name,
            isLink: pdf.isLink,
            link: pdf.link,
            individual_text: pdf.individual_text,
            textarea1: pdf.textarea1,
            textarea2: pdf.textarea2,
            refCentralizedFileId: pdf.refCentralizedFileId || '',
            checked: pdf.checked || false
          },
        ];
      }
      return prev;
    }, []);
    this.thridStagePdfs.map((pdf) => {
      pdf["touched"] = true;
    });

    await this.secondStageFormValidationMethod();
    if (!this.secondStageFormValidation) {
      this.page_2 = true;
      this.page_3 = false;
      this.selectedPage("page2");
      return false;
    };

    if (updatedData?.cart[0]?.productTax?.status) {
      if (!this.agbRequired) {
        this.agbAccepted.first = true;
      };

      if (this.mandatoryCheckIndex < 0) {
        if (this.third_stage_pricechange == true && this.pricChangeAccept == false) {
          this.third_stage_button = false;
          this.viewsnack6 = true;
          setTimeout(() => {
            this.viewsnack6 = false;
          }, 4500);
        } else {
          this.third_stage_button = true;
          this.third_stage_pricechange = false;
        };
      } else {
        this.third_stage_button = false;
        this.showPdfError = true;
      };
    } else {
      if (!this.agbRequired) {
        this.agbAccepted.first = true;
      };

      if (this.mandatoryCheckIndex < 0) {
        if (this.third_stage_pricechange == true && this.pricChangeAccept == false) {
          this.third_stage_button = false;
          this.viewsnack6 = true;
          setTimeout(() => {
            this.viewsnack6 = false;
          }, 4500);
        } else {
          this.third_stage_button = true;
          this.third_stage_pricechange = false;
        };
      };
    };

    if (this.third_stage_button) {
      this.url = window.location.href;
      var params = new URLSearchParams(this.url);
      let tempOrderId = decodeURIComponent(params.get("tempOrderId"));
      let sendData = {
        address: updatedData.postalCode,
        termsAndConditions: pdfData,
        tempSaleId: tempOrderId,
        co2Compensation: updatedData.co2Compensation,
      };

      if (this.sessionData['voucher'] && this.sessionData['voucher']['valid']) {
        sendData = Object.assign(sendData,
          {
            voucherCode: this.sessionData['voucher']['voucherCode'] == '' || !this.sessionData['voucher']['voucherCode'] ? undefined : this.sessionData['voucher']['voucherCode'],
            voucherId: this.sessionData['voucher']['voucherId'] == '' || !this.sessionData['voucher']['voucherId'] ? undefined : this.sessionData['voucher']['voucherId']
          }
        );
      } else {
        this.voucher['valid'] = false;
        this.voucher['voucherCode'] = '';
        this.voucher['voucherId'] = '';
        this.voucher['error'] = false;
      };

      if (this.sessionData.ratePaymentSettings.status) {
        let ratePaymentDoc = {
          transactionId: this.technicalTransectionIdFromEasyCredit,
          originalOrderAmount: '',
          currentOrderValue: '',
          status: '',
          date: '',
        };

        sendData = Object.assign(
          sendData,
          {
            easyCreditDoc: ratePaymentDoc
          },
        );
      }

      let userData = localStorage.getItem('portal_user') || '{}';
      userData = JSON.parse(userData);
      Object.keys(userData).length ? sendData['portalUser'] = userData : "" //sendData['portalUser']=tempobj
      this.loader = true;
      if (this.pricChangeAccept) {
        sendData['priceChangeAccepted'] = this.sessionData['cart'][0]['priceChangeAccepted'];
      }
      this.bigPCService.order(sendData).subscribe((data: any) => {
        this.loader = false;
        if (data.msg == "accountBlocked") {
          this.blockedUser = true;
          this.clickedoption = "Bestätigung";
          this.clickedPage = 'page4';
          this.page_4 = true;
          this.setRouteByStageNumber("four");
        };

        if (data.success && data.msg == "Successfully inserted orders") {
          this.establishSocketConnection({ creditCheck: true });
          this.clickedoption = "Bestätigung";
          this.clickedPage = 'page4';
          this.page_4 = true;
          localStorage.setItem("guestOrder", JSON.stringify(false));
          this.removeRatePayUrlParams()
          this.setRouteByStageNumber("four");
          // clear sid if user is not logged in
          if (!this.isLoggedIn) {
            localStorage.setItem("guestOrder", JSON.stringify(true));

            let sid = localStorage.getItem('sid');
            // alert("remvoing side for finalorder process")
            localStorage.removeItem(sid);
            localStorage.removeItem('sid');
            sessionStorage.clear();
            this.viewsnack = true;
            setTimeout(() => {
              this.viewsnack = false;
            }, 5000);
          } else {
            sessionStorage.removeItem('isNewVisit');
            this.viewsnack = true;
            setTimeout(() => {
              this.viewsnack = false;
            }, 4500);
          };

          this.dataLayer.push({
            'event': 'auprisConfirmOrder',
            'priceNet': this.selectedQuality[0]?.gross,
          });

          this.pricChangeAccept = false;
          this.scrollHelper.scrollToFirst("scrolltotopprogress");
          setTimeout(() => {
            var errorDivs = document.getElementsByClassName("scrolltotopprogress");
            if (errorDivs.length > 0) {
              errorDivs[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
              if (window && typeof window.scrollBy === 'function') {
                window.scrollBy(0, -50);
              };
            };
          }, 50);
          this.portalAuthService.manageSession();
        } else if (data.success == false && (data.msg == 'prices changed' || data.msg == 'calculation api failed')) {
          this.third_stage_pricechange = true;
          this.paymentProcessRetry = data['paymentBody'] && data['paymentBody']['ratePaymentInfo']
          this.priceChangewithPaymentMode = true;
          this.priceChangedData = data.data?.prices;
          this.oldPriceData = this.localStorageService.getSingleSessionData('cart');
        };
      }, () => {
        this.loader = false;
      });
    };
  };
  paymentProcessRetry: any;

  priceDetailsEvent(event) {

    // this is to change the router state with corresponding stage number
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    window.history.pushState("", document.title, url);

    if (url.includes("stage=one") && !url.includes("popup=pricedetails")) {
      newUrl = `${newUrl}&popup=pricedetails`;
      window.history.replaceState('', document.title, newUrl);
    };
    event.quantity = this.sessionData.quantity;
    const dialogRef = this.dialog.open(
      PricedetailPopupComponent, {
      panelClass: "my-full-screen-dialog",
      width: "500px",
      data: event,
    });

    dialogRef.componentInstance.symbol = this.symbol;
    dialogRef.componentInstance.languageVar = this.languageVar;

    dialogRef.afterClosed().subscribe((popup) => {
      if (newUrl.includes("stage=one")) {
        newUrl = newUrl.replace("&popup=pricedetails", "");
      };
      window.history.replaceState('', document.title, newUrl);
    });
  };

  async updateAffiliateQueryParams(paramKey, paramValue) {
    let getAffiliateStatus = await this.orderService.checkForAffliateQueryParams();
    if (getAffiliateStatus.status) {
      this.url = window.location.href;
      let params = new URLSearchParams(this.url);
      let paramExistingValue = params.get(paramKey);
      if (paramExistingValue && paramExistingValue != '') {
        let newUrl = `${this.url}`;
        newUrl = newUrl.replace(`${paramKey}=${paramExistingValue}`, `${paramKey}=${paramValue}`);
        window.history.pushState('', document.title, this.url);
        window.history.replaceState('', document.title, newUrl);
        this.affliateDetails = this.orderService.affliateDetails;
      }
    } else {
      if (paramValue && paramKey == 'amount') {
        this.url = window.location.href;
        let params = new URLSearchParams(this.url);
        let paramExistingValue = params.get(paramKey);
        let newUrl = `${this.url}`;
        newUrl = newUrl.replace(`${paramKey}=${paramExistingValue}`, `${paramKey}=${paramValue}`);
        window.history.pushState('', document.title, this.url);
        window.history.replaceState('', document.title, newUrl);
        this.affliateDetails = this.orderService.affliateDetails;
      }
    };
  };

  gettingNewSessionData(sessionId) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.smallPCService.getNewSessionData(sessionId).then((sessionData) => {
          resolve(sessionData);
        });
        // this.url = window.location.href;
        let params = new URLSearchParams(this.url);
        let OrderId = decodeURIComponent(params.get("tempOrderId"));
        if (((OrderId && OrderId != null && OrderId != "null") && (!this.url.includes("sid") || !sessionId))) {
          this.bigPCService.getTempOrderData({ tempSaleId: OrderId }).subscribe(async (saleTempInfo: any) => {
            // console.log(saleTempInfo, 3831);
            let tempInfo = saleTempInfo.data;
            let prepareAsLikeLocal = {
              localOptions: tempInfo.options,
              orderAddresses: tempInfo.addresses,
              domainName: tempInfo.bigPcUrls.domainName,
              cart: [],
              co2Compensation: tempInfo.co2Compensation,
              postalCode: tempInfo.address,
              stations: tempInfo.stations,
              quantity: tempInfo.quantity,
              ratePaymentSettings: tempInfo.ratePaymentDetails,
              product: {
                name: tempInfo.orderInfo.product,
                _id: tempInfo.dynamicProductId
              },
              priceCalcUrl: tempInfo.ratePaymentDetails,
              desiredDateSettings: tempInfo.desiredDate,
            };
            // this.sessionId = this.smallPCService.genereateNewSessionId();
            // let newURL = this.url;
            // newURL = `${newURL}&sid=${sessionId}`
            // window.history.pushState('', document.title, this.url);
            // window.history.replaceState('', document.title, newURL);
            await this.smallPCService.setSessionData(sessionId, prepareAsLikeLocal);
            await this.smallPCService.getNewSessionData(sessionId).then((sessionData) => {
              resolve(sessionData);
            });
            // resolve(prepareAsLikeLocal);
          });
        } else {
          await this.smallPCService.getNewSessionData(sessionId).then((sessionData) => {
            resolve(sessionData);
          });
        };
      } catch (error) {
        reject(false);
      };
    });
  };

  async checkRatePaymentParams() {
    // console.log(`%c credit chect`, 'background: #222; color: #bada55');
    this.loader = true;
    // this.secondStageFormValidation = true;
    this.url = window.location.href;
    let params = new URLSearchParams(this.url);
    let stage = decodeURIComponent(params.get("stage"));
    // console.log(`%c ${stage}`, 'background: #222; color: #bada55');
    if (stage == 'three') {
      let callback = decodeURIComponent(params.get("callback"));
      let OrderStatus = decodeURIComponent(params.get("tempOrderStatus"));
      let OrderId = decodeURIComponent(params.get("tempOrderId"));

      if (OrderStatus == 'cancel') {
        this.establishSocketConnection({ creditCheck: undefined });
        this.loader = false;
        return;
      };

      this.sessionData = this.localStorageService.getSessionData(this.sessionId);
      // console.log(`%c ${this.sessionData}`, 'background: #222; color: #bada55');
      if (!this.sessionData['pdfData']) {
        // console.log("pdfData not found",4113)
        // console.log(this.sessionData['cart'],4116)
        this.sessionData['pdfData'] = this.sessionData['cart'][0]['pdfs'];
        // console.log(this.sessionData['cart'])
      }
      // console.log(this.sessionData['pdfData'],4115)
      if (this.sessionData['pdfData']) {
        this.thridStagePdfs = this.sessionData['pdfData'];
        this.thridStagePdfs.map((pdf: any) => {
          pdf["checked"] = true;
        });
      };
      // console.log(this.sessionData['pdfData'], 4114)
      if (callback == 'true' && OrderStatus == 'success' && OrderId) {
        this.bigPCService.finalCreditCheck({ tempOrderId: OrderId }).subscribe(async (data) => {
          // console.log(data, 4118)
          if (data.creditStatusVerified) {
            this.third_stage_button = true;
            // let checkValidation = await this.secondStageFormValidationMethod();
            // console.log(checkValidation, 410666)
            // if (!checkValidation) {
            //   this.page_2 = true;
            //   this.page_3 = false;
            //   this.selectedPage("page2");
            //   return false;
            // } else {
            //  await this.finalOrderPlace();
            // };
          };
        }, (err) => {
          if (!err.error.creditStatusVerified) {
            this.loader = false;
            this.showPaymentSelection();
            this.establishSocketConnection({ creditCheck: false });
          };
        });
      } else if (callback == 'true' && OrderStatus == 'denial') {
        this.loader = false
        this.showPaymentSelection();
        this.establishSocketConnection({ creditCheck: undefined });
      };
    } else {
      this.loader = false;
      return;
    };
  };

  async showPaymentSelection() {
    let sessionInfo: any = await this.gettingNewSessionData(this.sessionId);
    this.sessionData = sessionInfo;
    this.loader = true;

    // let sendInfoForOptions = {
    //   dynamicProductId: this.sessionData.product._id,
    //   plzId: this.sessionData.postalCode.plzId
    // };
    this.PCOptions = [];
    this.bigPCService.dynamicOptions().subscribe(async (dynamicOptions: any) => {
      if (dynamicOptions.success) {
        this.loader = false;
        this.PCOptions = dynamicOptions.data;
        const filteredOptions = (this.PCOptions.find(item => item.optionType === 12)?.dynamicOptions || []).filter(option => option.centralizedOptions.name !== "Ratenzahlung" || (option.centralizedOptions.paymentOptionLink && option.centralizedOptions.paymentOptionLink == 'none'));
        let dialogRef = this.dialog.open(InfopopupComponent, {
          panelClass: "my-full-screen-dialog",
          disableClose: true,
          width: "500px",
          data: [...filteredOptions],
        });

        dialogRef.afterClosed().subscribe(async (data) => {

          let selected = filteredOptions[0]
          if (data?.sucess && data?.selectedPayOption && Object.keys(data?.selectedPayOption).length) {
            selected = data.selectedPayOption
          };

          let ratePaymentSettings = {
            status: false,
            zahlungRate: '',
            zahlungspans: []
          };

          let index = this.sessionData.localOptions.findIndex(item => item.name.match(/Ratenzahlung|RatenZaulung/ig));

          let selecteditemIndex = filteredOptions.findIndex(item => item._id == selected._id)
          let newSelectedPaymentOption = {
            optionSetId: selected.refCentralizedOptionSetId,
            optionId: selected.refCentralizedOptionId,
            DOB: selected.centralizedOptions?.DOBStatus ? true : false,
            dynamicPcOptionsSetId: selected.refOptionSetId,
            lable: "Zahlungsart",
            name: selected.centralizedOptions.name,
            sort: selecteditemIndex
          };

          if (index != -1) {
            let localOptions = this.sessionData.localOptions;
            localOptions[index] = newSelectedPaymentOption;
            this.sessionData = { ...this.sessionData, ratePaymentSettings: ratePaymentSettings, localOptions: localOptions, bankDetails: {} };

            localStorage.setItem(this.sessionId, JSON.stringify(this.sessionData));
            this.NavigateToFirstStage();
          };
        });
      };
    });
  };

  async NavigateToFirstStage() {
    this.url = window.location.href;
    const paramMap = new URLSearchParams(this.url);
    const stage = paramMap.get('stage')

    const paramsToReplace = {
      'orderId': paramMap.get('tempOrderId'),
      'callback': paramMap.get('callback'),
      'tempOrderStatus': paramMap.get('tempOrderStatus'),
      'socketId': paramMap.get('socketId'),
    };

    Object.entries(paramsToReplace).forEach(([param, value]) => {
      if (value) {
        this.url = this.url.replace(new RegExp(`&${param}=${value}`, 'gi'), '');
      };
    });

    if (stage) {
      this.url = this.url.replace(/&stage=[^&]*/gi, '&stage=one');
    };

    window.history.replaceState('', document.title, this.url);
    await this.getProductDatabyUnter();
    this.fetchCalculatedQualities()
    this.currentPage = 'one';
    this.showBigPC = true;
    this.page_1 = this.page_2 = this.page_3 = false;
    this.clickedPage = "page1";
    this.clickedoption = "Bestelloptionen";
  };

  getUrls(url: any, mainUrls: any) {
    const hostname = window && window.location && window.location.hostname;

    if (environment.testSite_or_thirdPartySites) {
      return mainUrls;
    };

    let obj = {
      "priceCalcUrl": "",
      "domainName": this.hostname
    };

    if (/^.*localhost.*/.test(hostname)) {
      obj.priceCalcUrl = `http://localhost:4900/#&Domainname=${this.hostname}`;
    } else if (/^test2.aupris.com/.test(hostname) || /^test.aupris.com/.test(hostname)) {
      obj.priceCalcUrl = `${environment.pricecalc}/#&Domainname=${this.hostname}`;
    };
    return obj;
  };

  nextError() {
    if (this.currentErrorIndex < this.error.length - 1) {
      this.currentErrorIndex++;
    } else {
      this.contstrains = false;
    };
  };

  establishSocketConnection(options?: any) {
    return new Promise((resolve, reject) => {
      // console.log("creating socket connection-started");
      if (this.socket) this.closeSocketConnection();
      this.socket = io('http://localhost:3900/pcPayment', {
        path: '/pcPaymentSocket',
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        transports: ['websocket'],
        query: {
          token: "",
          sessionId: this.sessionId,
          domain: this.hostname,
          tempOrderId: decodeURIComponent(new URLSearchParams(window.location.href).get("tempOrderId")),
          callback: decodeURIComponent(new URLSearchParams(window.location.href).get("callback")),
          tempOrderStatus: decodeURIComponent(new URLSearchParams(window.location.href).get("tempOrderStatus")),
        }
      });
      this.socketId = this.socket.id;

      this.socket.on('paymentUpdate', (socketData) => {
        // console.log("socket", socketData)
        if (socketData.tempOrderStatus == 'success' && options?.creditCheck) {
          this.clickedoption = "Bestätigung";
          this.clickedPage = 'page4';
          this.page_4 = true;
          localStorage.setItem("guestOrder", JSON.stringify(false));
          this.removeRatePayUrlParams()
          this.setRouteByStageNumber("four");
          // clear sid if user is not logged in
          if (!this.isLoggedIn) {
            localStorage.setItem("guestOrder", JSON.stringify(true));
            let sid = localStorage.getItem('sid');
            alert("removing sid for establish connection");
            localStorage.removeItem(sid);
            localStorage.removeItem('sid');
            sessionStorage.clear();
            this.viewsnack = true;
            setTimeout(() => {
              this.viewsnack = false;
            }, 5000);
          } else {
            sessionStorage.removeItem('isNewVisit');
            this.viewsnack = true;
            setTimeout(() => {
              this.viewsnack = false;
            }, 4500);
          };

          this.dataLayer.push({
            'event': 'auprisConfirmOrder',
            'priceNet': this.selectedQuality[0]?.gross,
          });

          this.pricChangeAccept = false;
          this.scrollHelper.scrollToFirst("scrolltotopprogress");
          setTimeout(() => {
            var errorDivs = document.getElementsByClassName("scrolltotopprogress");
            if (errorDivs.length > 0) {
              errorDivs[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
              window.scrollBy(0, -50);
            }
          }, 50);
          this.portalAuthService.manageSession();
          this.socket.disconnect();
        } else if (socketData.tempOrderStatus == 'success' && !options?.creditCheck) {
          this.socket.disconnect();
        } else if (socketData.tempOrderStatus == 'cancel') {
          this.socket.disconnect();
        } else if (socketData.tempOrderStatus == 'denial') {
          this.socket.disconnect();
        } else {
          // this.socket.disconnect();
        };
      });

      this.socket.on('connect', () => {
        // console.log('Socket connected:', this.socket.id);
        this.socketId = this.socket.id;
        resolve(this.socket.id);
      });

      this.socket.on('connect_error', (error) => {
        // console.log("socket connection failed", { error });
        reject(error);
      });
    });
  };

  closeSocketConnection() {
    if (this.socket) {
      this.socket.emit('disConnectCustomerSocket');
      this.socket.disconnect();
    };
  };

  removeRatePayUrlParams() {
    this.url = window.location.href;
    const paramMap = new URLSearchParams(this.url);
    const stage = paramMap.get('stage')

    const paramsToReplace = {
      'tempOrderId': paramMap.get('tempOrderId'),
      'callback': paramMap.get('callback'),
      'tempOrderStatus': paramMap.get('tempOrderStatus'),
      'socketId': paramMap.get('socketId')
    };

    Object.entries(paramsToReplace).forEach(([param, value]) => {
      if (value) {
        this.url = this.url.replace(new RegExp(`&${param}=${value}`, 'gi'), '');
      }
    });
    window.history.replaceState('', document.title, this.url);
  }

  movingToStage2CheckWithTempOrderId() {
    let params = this.localStorageService.SearchURLParams();
    let tempOrderId = this.localStorageService.decodeURLParams(params, "tempOrderId")
    if (tempOrderId != "null" || tempOrderId != null) {
      // if order id available then need to check the status of the order at stage1 and stage2
      if (this.sessionData['orderAddresses'] && this.sessionData['orderAddresses'].hasOwnProperty('deliveryAddress') && this.sessionData['orderAddresses']['deliveryAddress'].length && this.sessionData['orderAddresses']['deliveryAddress'][0]['zipCode'] != this.sessionData['postalCode']['postalcode']) {
        this.sessionData['orderAddresses'] = {
          deliveryAddress: [],
          billingAddress: [],
        };
        this.localStorageService.setSessionData(this.sessionData);
      }
    }
  }

};


export class ScrollHelper {
  private classToScrollTo: string = null;

  scrollToFirst(className: string) {
    this.classToScrollTo = className;
  }

  doScroll() {
    if (!this.classToScrollTo) {
      return;
    }
    try {
      var elements = document.getElementsByClassName(this.classToScrollTo);
      if (elements.length == 0) {
        return;
      };
      elements[0].scrollIntoView();
    } finally {
      this.classToScrollTo = null;
    };
  };

};


// if need in can use this//
// if(sendData.tempSaleId != '' && sendData.tempSaleId != "null" && sendData.tempSaleId != "undefined" && sendData.tempSaleId != null && sendData.tempSaleId != undefined){
//   sendData = Object.assign(sendData , {redirectLinks: this.createRedirectUrls(orderIdTemp)})
// }
